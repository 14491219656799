import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import logoImage from '../../../Assets/img/genx-logo.png'; 
// ../../../assets/img/webp/genx-logo.png'

 
    const generatePDF = (quotation,pack, review,baseTotal,quantityFL,totalFurnitureL,quantityFM,totalFurnitureM,quantityFS,totalFurnitureS,quantityAL,totalAppliancesL,quantityAM,totalAppliancesM,quantityAS,totalAppliancesS,tBoxes,tLuggage,TransportTotal,totalPacking,subTotal,gst,totalAmount,totalPackingamount,gsts,storageDiscount,packingDiscount) => {
      // baseTotal
      const doc = new jsPDF();

      const currentDate =  new Date();
      const newDate = currentDate.toLocaleDateString()

    // Add logo to the right side of the header
      const logoWidth = 25; 
      const logoHeight = 10; 
      const logoX = doc.internal.pageSize.getWidth() - 40; 
      const logoY = 20;
      doc.addImage(logoImage, 'PNG', logoX, logoY, logoWidth, logoHeight);

      // Add custom styles
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color
      
      // doc.text('Quotation', 100, 10);
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.text('Genx Relocator And Enterprises Pvt Ltd:', 15, 20);
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(9);
      doc.text('# 1-25-181/3 ,Plot No.121 & 122,', 15, 25);
      doc.text('Brundavan Nagar, Gollaguda,', 15, 30);
      doc.text('Trimulgherry -Secunderabad', 15, 35);
      doc.text('Telangana -500015',15,40 )
      doc.text('Phone: +91 98660 12094/+91 91009 90090', 15, 50);
      doc.text('Email:info@genxrelocators.com', 15,55)
      doc.text(`Date:${newDate}`,15,63)
      // { align: "right", lineHeightFactor: 1.5 }



      doc.text(`${review.name}`, 195, 48, { align: "right" });
      doc.text(`${review.email}`, 195, 53, { align: "right" });
      doc.text(`${review.mobilenumber}`, 195, 58, { align: "right" });
      doc.text(`${review.area}`, 195, 63, { align: "right" });

      doc.setDrawColor(0); // Set color to black
      doc.setLineWidth(0.5);
      doc.line(15, 66, 195, 66);
      
      const valueX2 = 185;
      const data = [
        //  ['Base Plan',`${baseTotal}`,'1',`${baseTotal}`],
         ['Furniture Large Items:                                                                         Almariah, Cot, Cupboard, Diwan, Dining-Table, Dressing-Table, Mattress, Sofa, Seater, etc.',`${quotation.fLargeValue}`,`${quantityFL}`, (`${totalFurnitureL}`) ,{ align: "right" }],
         
        ['Furniture Medium Items:                                                                  Center-table, Dining-Chairs, Foldable-Mattress, Office-Chair, Small-Cabinet, Small-cupboard,Side-Table, etc.', `${quotation.fMediumValue}`,`${quantityFM}`,`${totalFurnitureM}`,{ align: "right" } ], 
        ['Furniture Small Items:                                                                       Miscellaneous items like Buckets, Planks, Plastic-Chairs, Stands, Small-Show-Stands, etc.', `${quotation.fSmallValue}`,`${quantityFS}`, `${totalFurnitureS}`,{ align: "right" }],
        ['Appliances Large Items:                                                                   AC Outdoor, AC indoor,Dish Washer, Fridge, Monitor, TV, Washing Machine, etc.',`${quotation.aLargeValue}`,`${quantityAL}`, (`${totalAppliancesL}`) ,{ align: "right" }],
        ['Appliances Medium Items:                                                                Air Cooler, Music System, Water Purifier, Table Fan etc.,', `${quotation.aMediumValue}`,`${quantityAM}`,`${totalAppliancesM}` ,{ align: "right" }], 
        ['Appliances Small Items:                                                             Ceiling Fan, Cooker, Gas Stove, Grinder, Grills, Induction stove, Microwave, Toaster, etc.', `${quotation.aSmallValue}`,`${quantityAS}`, `${totalAppliancesS}`,{ align: "right" }],
       
        ['Carton Box:                                                                                         Box size - 550mm x 380mm x 380mm (Premium Quality - 180 GSM)',`${quotation.boxes}`, `${review.boxes}`, `${tBoxes}`,{ align: "right" }],
        // `${quotation.boxes}`, `${review.boxes}`, `${tBoxes}`
        ['Medium Luggage:                                                                                 < 40 Ltrs', `${quotation.Luggauge}`,`${review.luggage}`,(`${tLuggage}`),{ align: "right" }],
        // , `${quotation.Luggauge}`,`${tLuggage}`,`${review.luggage}`
     
      ];


     // Adding space before printing "Subtotal"
     doc.setFont('Arial', 'normal');
     doc.setFontSize(11);
     const valueX1 = 195; // X-coordinate for the values
   
     doc.text('Subtotal', 160, 215);
     doc.text(`${subTotal}`, valueX1, 215, { align: "right" }); // Aligning to the right
    //  doc.text('Discount ', 160, 222);
    //  doc.text(`${discount}`, valueX1, 222, { align: "right", lineHeightFactor: 1.5 });
    doc.text('Discount ', 160, 222);
    doc.text(`${storageDiscount}`, valueX1, 222, { align: "right", lineHeightFactor: 1.5 });
    // Aligning to the right
     doc.text('GST 18% ', 160, 230);
     doc.text(`${gst}`, valueX1, 230, { align: "right", lineHeightFactor: 1.5 }); // Aligning to the right
     doc.setFont('helvetica', 'bold');
     doc.setFontSize(11);
     doc.text('Total ', 160, 238);
     doc.text(`${totalAmount}`, valueX1, 238, { align: "right", lineHeightFactor: 1.5 }); // Aligning to the right
     

      doc.setFont('Arial', 'normal');
      doc.setFontSize(10);

      const longText = `Discount of 10% on 06 Months Rental Services by making upfront Payment & Discount of 20% on 01 Year above Rental Services `;

      // Split the long text into smaller parts using newline character (\n)
      const parts = longText.split('\n');
      // Add each part of the text to the PDF, with appropriate coordinates
      parts.forEach((part, index) => {
        doc.text(part, 10, 255 + index * 10); // Adjust the vertical position (10) for each line
      });
      // doc.setFont('helvetica', 'bold');
      // doc.setFontSize(10);
      // doc.text('Notes',10,270)

      doc.setFont('Arial', 'normal');
      const Longtxt = ` Above Estimation Is Based on The Goods Details Communicated. Charges May Change Upon Final Volume of Goods`
      const content = Longtxt.split('\n');
      content.forEach((part,indexs) => {
        doc.text(part,10, 265 + indexs * 10);
      })

      doc.setFont('Arial', 'normal');
      const Longtxtn = ` Minimum Billing Rs.1000/- for Rental Services`
      const contentn = Longtxtn.split('\n');
      contentn.forEach((part,indexs) => {
        doc.text(part,10, 275 + indexs * 10);
      })

      doc.line(10, 280, 200,280);
      doc.text('© Copyright Erinome  2024',10,285)



      const options = {
       
       
        headStyles: { fillColor: [248, 249, 250] }, // Style for the table header
        bodyStyles: { textColor: [0, 0, 0] }, // Style for the table body
        // columnStyles: { 0: { cellWidth: 40 }, 1: { cellWidth: 30 }, 2: { cellWidth:30 } }, // Custom column widths
    };
      doc.autoTable({
        startY: 74,
        head: [['Item', 'UnitCost','Quantity','Total']],
        body: data.slice(0), // Use the rest of the arrays in data as the body
        theme: 'plain', // Theme for the table
        styles: {
            overflow: 'linebreak', // Overflow behavior
            fontSize: 9, // Font size
            fontStyle: 'normal', // Font style
            cellPadding: 2, // Padding for cells
            minCellHeight: 12, // Height of row
         
        },
        ...options, // Spread options object
        columnStyles: {
          0: {cellWidth:100},
          1: { align: 'right' ,cellWidth: 25 }, // Aligning the second column to the right
          2: { align: 'right',cellWidth: 28}, // Aligning the third column to the right
          3: { align: 'right',cellWidth: 28 }, // Aligning the fourth column to the right
         
      },
      didParseCell: function (data) {
        if (data.section === 'body' && data.column.index > 0) {
            data.cell.styles.halign = 'right'; // Align text to the right for all columns except the first one
        }
        if (data.section === 'head' && ['UnitCost', 'Quantity', 'Total'].includes(data.cell.raw)) {
          data.cell.styles.halign = 'right';
          data.cell.styles.fontStyle = 'bold'; 
          data.cell.styles.fontSize = 10;
      }
      if(data.section === 'head' && ['Item'].includes(data.cell.raw)) {
        data.cell.styles.fontStyle = 'bold';
        data.cell.styles.fontSize = 10;
        
      }
    }
   
 });
   // ....end of  first page.....
  


     // ....start of second page.....
      doc.addPage();
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0); // Black color

      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.text('Genx Relocator And Enterprises Pvt Ltd:', 15, 20);
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(9);
      doc.text('# 1-25-181/3 ,Plot No.121 & 122,', 15, 25);
      doc.text('Brundavan Nagar, Gollaguda,', 15, 30);
      doc.text('Trimulgherry -Secunderabad', 15, 35);
      doc.text('Telangana -500015',15,40 )
      doc.text('Phone: +91 98660 12094/+91 91009 90090', 15, 50);
      doc.text('Email:info@genxrelocators.com', 15,55);
      doc.text(`Date:${newDate}`,15,63)
      
      const logWidth = 25; 
      const logHeight = 10; 
      const logX = doc.internal.pageSize.getWidth() - 40; 
      const logY = 20;
      doc.addImage(logoImage, 'PNG', logX, logY, logWidth, logHeight);

      doc.text(`${review.name}`, 195, 53, { align: "right" });
      doc.text(`${review.email}`, 195, 58, { align: "right" });
      doc.text(`${review.mobilenumber}`, 195, 63, { align: "right" });
      doc.setDrawColor(0); // Set color to black
      doc.setLineWidth(0.5);
      doc.line(15, 66, 195, 66);

      const data1 = [
        
        ['Transportation Charges', `${TransportTotal}`],
        ['Packing Charges', `${pack}`],
        
    ];
    
    
    const options1 = {
        startY: 74, // Y position from where the table will start
     
        headStyles: { fillColor: [248, 249, 250] }, // Style for the table header
        bodyStyles: { textColor: [0, 0, 0] }, // Style for the table body
        // columnStyles: { 0: { cellWidth: 100 }, 2: { cellWidth: 30 }, 5: { cellWidth:30 } }, // Custom column widths
    };
    
    // Generate the table
    doc.autoTable({
      head: [['Item','Total']], 
      startY: 74,
     
      body: data1.slice(0), 
      theme: 'plain', // Theme for the table
      styles: {
          overflow: 'linebreak', 
          fontSize: 9, 
          fontStyle: 'normal', 
          cellPadding: 2, 
          minCellHeight: 12,
          rowWidth:20,
          halign: 'left', 
      },
      ...options, // Spread options object
      didParseCell: function (data1) {
        if (data1.section === 'body' && data1.column.index > 0) {
            data1.cell.styles.halign = 'right'; // Align text to the right for all columns except the first one
        }
        if (data1.section === 'head' && ['Total'].includes(data1.cell.raw)) {
          data1.cell.styles.halign = 'right'; // Align text to the right for specified column headers
          data1.cell.styles.fontStyle = "bold";
          data1.cell.styles.fontSize = '10';
      }
      if(data1.section === 'head' && ['Item'].includes(data1.cell.raw)){
        data1.cell.styles.fontStyle = 'bold';
        data1.cell.styles.fontsize = '10';
        
      }
    }   
    
    });


    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    const textX = 145; // X-coordinate for the text
    const valueX = 195; // X-coordinate for the value
    
    doc.text('Subtotal', textX, 116);
    doc.text(`${totalPacking}`, valueX, 116, { align: "right", lineHeightFactor: 1.5 });
    /* doc.text('Discount ', textX, 124);
    doc.text(`${packingDiscount}`, valueX, 124, { align: "right", lineHeightFactor: 1.5 }); */ // Aligning to the right
    doc.text('GST 18% ', textX, 124);
    doc.text(`${gsts}`, valueX, 124, { align: "right", lineHeightFactor: 1.5 }); // Aligning to the right
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(11);
    doc.text('Total ', textX, 132);
    doc.text(`${totalPackingamount}`, valueX, 132, { align: "right", lineHeightFactor: 1.5 }); // Aligning to the right


      doc.setFont('Arial', 'normal');
      doc.setFontSize(10);

    
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text('TERMS AND CONDITIONS:-',10,195)

      doc.setFont('Arial', 'normal');
      const Longtxt1 = `Goods insurance covered upto 3 Lakhs for all your storage goods.`
      const content1 = Longtxt1.split('\n');
      content1.forEach((part,indexs) => {
        doc.text(part,10, 200 + indexs * 10);
      })

      const longText1 = `Payment terms -Payment for packing & transportation immediate after pickup. For Storage after completion of month within`;
      // Split the long text into smaller parts using newline character (\n)
      const parts1 = longText1.split('\n');
     // Add each part of the text to the PDF, with appropriate coordinates
     parts1.forEach((part, index) => {
       doc.text(part, 10, 205 + index * 10); // Adjust the vertical position (10) for each line
     });

     const longText1a = `  02 days of due date.`;
     // Split the long text into smaller parts using newline character (\n)
     const parts1a = longText1a.split('\n');
    // Add each part of the text to the PDF, with appropriate coordinates
    parts1a.forEach((part, index) => {
      doc.text(part, 10, 210 + index * 10); // Adjust the vertical position (10) for each line
    });

     const longText2 = `Handyman services like electronic goods & plumbing work will not be undertaken by Genx team. Upon Request we can arrange `;
     // Split the long text into smaller parts using newline character (\n)
     const parts2 = longText2.split('\n');
    // Add each part of the text to the PDF, with appropriate coordinates
    parts2.forEach((part, index) => {
      doc.text(part, 10, 215 + index * 10); // Adjust the vertical position (10) for each line
    });

    
    const longText2a = `on actual basis.`;
    // Split the long text into smaller parts using newline character (\n)
    const parts2a = longText2a.split('\n');
   // Add each part of the text to the PDF, with appropriate coordinates
   parts2a.forEach((part, index) => {
     doc.text(part, 10, 220 + index * 10); // Adjust the vertical position (10) for each line
   });

    const longText3 = `Genx will not store any Food items, Liquids, Full Gas Cylinder, or anything prohibited in nature.`;
    // Split the long text into smaller parts using newline character (\n)
    const parts3 = longText3.split('\n');
   // Add each part of the text to the PDF, with appropriate coordinates
   parts3.forEach((part, index) => {
     doc.text(part, 10, 225 + index * 10); // Adjust the vertical position (10) for each line
   });

   const longText4 = `Warehouse is equipped with all security measures like -CCTV -Fire Extinguisher -Rodents & Pest Control `;
   // Split the long text into smaller parts using newline character (\n)
   const parts4= longText4.split('\n');
  // Add each part of the text to the PDF, with appropriate coordinates
  parts4.forEach((part, index) => {
    doc.text(part, 10, 230 + index * 10); // Adjust the vertical position (10) for each line
  });
  const longText5 = `Re-Delivery charges will be collected at actuals.  `;
   // Split the long text into smaller parts using newline character (\n)
   const parts5= longText5.split('\n');
  // Add each part of the text to the PDF, with appropriate coordinates
  parts5.forEach((part, index) => {
    doc.text(part, 10, 235 + index * 10); // Adjust the vertical position (10) for each line
  });

      
      // doc.text('Best Regards', 15,160,{ fontWeight:"bold" })
      // doc.text('The GenX Team', 15, 165)
      
      doc.line(10, 280, 200,280);
      doc.setFont('Arial', 'normal');
      doc.setFontSize(9);
      doc.text('© Copyright Erinome  2024',10,285)
      doc.save('quotation.pdf');
      
      
    };


export default generatePDF;