import React, { useEffect, useState } from "react";

// Libraries
import { Button, Card, Col, Container, Modal, Navbar, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Parallax } from "react-scroll-parallax";
import { Formik, Form, FormikConsumer } from 'formik';
import { m, AnimatePresence } from 'framer-motion';
import * as Yup from 'yup';

// Component
import Header, { HeaderNav, Menu } from "../../Components/Header/Header";
import { Input } from "../../Components/Form/Form";
import Buttons from "../../Components/Button/Buttons";
import axios from "axios";
import DurationPage from "../InstaQuote/household/DurationPage";
import DocumentPage from "../InstaQuote/DocumentPage"
import VehiclePage from "../InstaQuote/VehiclePage"
import CorporatePage from "../InstaQuote/CorporatePage"
import ContainerRentals from "./ContainerRentals";
import ClosePopup from "./ClosePopup";
import ThankYou from "../Home/ThankYou";


// Data



const Register = (props) => {
const swiperRef = React.useRef(null);
const params = useParams()
const storageType = props.newStorage
const [showModal, setShowModal] = useState(false);
const handleCloseModal = () => setShowModal(false);
const handleShowModal = () => setShowModal(true);

const navigate = useNavigate()

const [user,setUser]=useState({
name:"",
email:"",
mobilenumber:"",
area:""
})

const [fullscreen, setFullscreen] = useState(true);
const [show, setShow] = useState(false);

const readValue = async(e)=>{
const {name,value} = e.target
setUser({...user,[name]:value})
}





const handleShow = async(breakpoint)=>{
 

try {
console.log(user);
console.log('Storage type:', storageType);
if (user.mobilenumber.length !== 10) {
  
  alert('Mobile number must be 10 digits long');
  return;
}

await axios.patch(`${process.env.REACT_APP_API}/api/member/register/${props.newId}`, user)

.then(res=>{ console.log(res.data.user);

  
  // 
})
.catch(err=>{
console.log(err.message)
})
} catch (error) {
console.log(error.message)
}



setFullscreen(breakpoint);
setShow(true);
}

const renderModalContent = () => {
  switch (storageType) {
    case 'Household':
      return <DurationPage newID = {props.newId}/>;
    case 'Document':
      return <DocumentPage />;
    case 'Vehicle':
      return <VehiclePage />;
    case 'Corporate':
      return <CorporatePage />;
      case 'Container':
        return <ContainerRentals />;
    default:
      return null;
  }
};



// const sendRegistrationEmail = async (email) => {
//   try {
//       await axios.post(`${process.env.REACT_APP_API}/api/member/sendmail`,{email})
//       .then(res=>{console.log(res.data.msg)})
//       .catch(err=>{console.log(err.message)})
//   } catch (error) {
//       console.error('Failed to send registration email:', error.response.data);
//   }
// };


const handleToggleModal = () => setShowModal(prev => !prev);


// function handleKeyDown(event) {
//   const maxLength = 10; // Maximum length of the mobile number
//   const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight"]; // Allow navigating through input
//   const isDigit = /^\d$/; // Regex to check if the input is a digit

//   // Allow only digits and certain control keys
//   if (!isDigit.test(event.key) && !allowedKeys.includes(event.key)) {
//     event.preventDefault();
//     return;
//   }

//   // Prevent input if maximum length is reached and the key pressed is not Backspace
//   if (event.target.value.length >= maxLength && event.key !== "Backspace") {
//     event.preventDefault();
//   }
// }



return (
<div style={props.style}>

  {/* Header Start*/}
  <Header topSpace={{ md: true }} type="reverse-scroll">
    <HeaderNav fluid="fluid" theme="dark" expand="lg"
      className="py-[40px]  px-[35px] md:px-[15px] md:py-[20px] sm:px-0">
      <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
      <Link aria-label="header logo" className="flex items-center" to="/">
      <Navbar.Brand className="inline-block p-0 m-0">
        <img className="default-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-white@2x.webp' alt='logo' />
        <img className="alt-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-green-dark@2x.webp' alt='logo' />
        <img className="mobile-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-black@2x.webp' alt='logo' />
      </Navbar.Brand>
      </Link>
      </Col>

    </HeaderNav>
    <span onClick={handleToggleModal} className="cursor-pointer float-right pe-[50px] me-[50px] text-dark xs:me-[0px] " style={{ fontSize: '20px' }}>✖</span>
    <Modal show={showModal} onHide={handleCloseModal} centered size="md">
        
        <Modal.Body>
          {/* Your long popup content */}
          {/* You can render the InstaQuote2 component here or any other content */}
         
        </Modal.Body>
        <ClosePopup/>
        {/* You can add a footer if needed */}
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
  </Header>
  {/* Header Start*/}

  <Col xl={{ span: 6, offset: 3 }} md={12} className="lg:px-[30px] xs:py-[100px] md:pt-[100px] md:px-[15px]">

  <div  className="p-16 m-20 md:p-16 md:m-20 xs:p-5 md:pt-5 xs:m-5 border border-mediumgray lg:mt-[35px]  md:p-0">
    <Formik>
      {({ isSubmitting, status }) => (
      <Form onSubmit={(e)=>  { e.preventDefault();
                               handleShow(true);}}>
                                <label className="py-50px font-serif font-medium mb-2 text-darkgray fs-4 ms-1 md:pt-[50px]" htmlFor="">Make sure to leave traces, so we can serve you better</label>
        <Input required showErrorMsg={false} name="name" id='name' value={user.name} onChange={readValue} type="text"
          labelClass="mb-[20px]" label={<div className="mb-[15px]" >Name <span className="text-[#fb4f58]">*</span>
  </div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]"
  placeholder="Enter your name" />
  <Input required showErrorMsg={false} name="email" type="email" value={user.email} onChange={readValue} id='email'
    labelClass="mb-[20px]" label={<div className="mb-[15px]">Email address <span className="text-[#fb4f58]">*</span>
</div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]"
placeholder="Enter your email" />
<Input required showErrorMsg={false} name="mobilenumber" type="number" value={user.mobilenumber} onChange={readValue}
  id='mobilenumber' labelClass="mb-[20px]" label={<div className="mb-[15px]">Mobile Number <span
  className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid
border-[#dfdfdf] text-md leading-[initial]" pattern = "[0-9]{10}"  placeholder="Enter your mobile number" />

{/* <Input required showErrorMsg={false} type="text" name="area" id='area' value={user.area} onChange={readValue} 
          labelClass="mb-[20px]" label={<div className="mb-[15px]" >Your Location <span className="text-[#fb4f58]">*</span>
  </div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]"
  placeholder="Enter your Location. Ex: HiTech City, Jubilee Hills" />
 */}

<Buttons ariaLabel="register" type="submit" className="btn-fill btn-fancy w-full
  font-medium font-serif rounded-none uppercase md:mb-[15px] sm:mb-0" themeColor="#232323" color="#fff" size="md"
  title="Submit" />
  <Modal show={show} fullscreen={fullscreen}>

    <Modal.Body>
      {renderModalContent()}
    </Modal.Body>
  </Modal>
  </Form>
  )}
  </Formik>
  </div>
  </Col>





  </div>
  );
  };

  export default Register;