import React, { useState } from "react";

// Libraries
import { Button, Card, Col, Container, Modal, Navbar, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Parallax } from "react-scroll-parallax";
import { Formik, Form } from 'formik';
import { m, AnimatePresence } from 'framer-motion';
import * as Yup from 'yup';

// Component
import Header, { HeaderNav, Menu } from "../../../Components/Header/Header";
import { Input } from "../../../Components/Form/Form";
import Buttons from "../../../Components/Button/Buttons";
import axios from "axios";
import { fadeIn } from "../../../Functions/GlobalAnimations";
import MoveInPage from "./MoveInPage";
import ClosePopup from "../ClosePopup";
import generatePDF from "./PDFGenerator";
import generatePDF2 from "./PDFGenerator2"




// Data







const QuotationPage = (props) => {
const swiperRef = React.useRef(null);
const navigate = useNavigate() 
const [showModal, setShowModal] = useState(false);
const handleCloseModal = () => setShowModal(false);
const handleShowModal = () => setShowModal(true);

const handleToggleModal = () => setShowModal(prev => !prev);

const [quoteFullscreen, setQuoteFullscreen] = useState(true);
  const [quoteShow, setQuoteShow] = useState(false);
  console.log(props.newid)

  const handleShow1 = async(breakpoint)=>{
    setQuoteFullscreen(breakpoint);
    setQuoteShow(true)
    console.log(props.newid)
  }
//pdf related


const handleGeneratePDF = () => {
  // const totalLarge = props.quantityL * props.quotation;

  

  const subTotal = props.totalFurnitureL + props.totalFurnitureM + props.totalFurnitureS + props.totalAppliancesL + props.totalAppliancesM + props.totalAppliancesS + props.tBoxes + props.tLuggage;
  // const discount = parseInt(subTotal * (1 / 10) ) ;
  // const totalOfDiscount = subTotal - discount;
  // console.log(totalOfDiscount);
  let storageDiscount;
  if(props.review.duration === "6-12months"){
    storageDiscount = parseInt(subTotal * 0.1 );
  }else if (props.review.duration === "12+ Months"){
    storageDiscount = parseInt(subTotal * 0.2 );
  }else{
    storageDiscount = 0;
  }
  console.log(storageDiscount);
  const disGrandTotal = subTotal - storageDiscount;
  const gst = parseInt(disGrandTotal * (18/100));
  const totalAmount = disGrandTotal  + gst;

  const totalPacking = props.SubTotal;
  /* let packingDiscount;
  if(props.review.duration === "6-12months"){
     packingDiscount = parseInt(props.SubTotal * (1/20) );
  }else if (props.review.duration === "12+ Months"){
    packingDiscount = parseInt(props.SubTotal * (1/10) );
  }else{
    packingDiscount = 0;
  } */
  const totalDiscount = totalPacking ;
  const gsts = parseInt(totalDiscount * (18/100));
  const totalPackingamount = totalPacking  + gsts;

  generatePDF(props.quotation, props.pack, props.review,props.baseTotal,props.quantityFL, props.totalFurnitureL,
    props.quantityFM, props.totalFurnitureM,props.quantityFS,props.totalFurnitureS,
    props.quantityAL,props.totalAppliancesL,props.quantityAM, props.totalAppliancesM,  props.quantityAS, props.totalAppliancesS,props.tBoxes, props.tLuggage,props.TransportTotal,props.SubTotal,subTotal,gst,totalAmount,totalPackingamount,gsts,storageDiscount,totalPacking)

  // if (props.review.assistance === "yes") {
  //   // Generate single-page PDF logic here
   
  
  // } else {
  //   // Generate double-page PDF logic here
    
  // generatePDF2(props.quotation, props.pack, props.review,props.quantityFL, props.totalFurnitureL,
  //   props.quantityFM, props.totalFurnitureM,props.quantityFS,props.totalFurnitureS,
  //   props.quantityAL,props.totalAppliancesL,props.quantityAM, props.totalAppliancesM,  props.quantityAS, props.totalAppliancesS,props.tBoxes, props.tLuggage,subTotal,discount,gst,totalAmount, props.baseTotal)
  // }

  






  }


return (
<div style={props.style}>

  {/* Header Start*/}
  <Header topSpace={{ md: true }} type="reverse-scroll">
    <HeaderNav fluid="fluid" theme="dark" expand="lg"
      className="py-[40px]  px-[35px] md:px-[15px] md:py-[20px] sm:px-0">
      <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
      <Link aria-label="header logo" className="flex items-center" to="/">
      <Navbar.Brand className="inline-block p-0 m-0">
        <img className="default-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-white@2x.webp' alt='logo' />
        <img className="alt-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-green-dark@2x.webp' alt='logo' />
        <img className="mobile-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-black@2x.webp' alt='logo' />
      </Navbar.Brand>
      </Link>
      </Col>

    </HeaderNav>
    <span onClick={handleToggleModal} className="cursor-pointer float-right pe-[50px] me-[50px] text-dark xs:me-[0px] " style={{ fontSize: '20px' }}>✖</span>
    <Modal show={showModal} onHide={handleCloseModal} centered size="md">
        
        <Modal.Body>
          {/* Your long popup content */}
          {/* You can render the InstaQuote2 component here or any other content */}
         
        </Modal.Body>
        <ClosePopup/>
        {/* You can add a footer if needed */}
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
  </Header>
  {/* Header Start*/}

  <m.section className="py-[70px] lg:py-[90px] sm:py-[75px] xs:py-[100px]" {...fadeIn}>
    <Container>
      <Row>
        <Col xl={{ span: 8, offset: 2 }} md={12} className=''>
        

        <div className="bg-lightgray p-[50px] lg:py-[30px] lg:px-[20px] md:py-5">
        

          <div className="row" >

            <h1 className="font-serif  text-lg text-success mt-5 mb-0  inline-block fs-1 font-medium">Quotation</h1>

            <div className="col-md-6">
            <h1 className="font-serif  text-center text-lg  mt-5 mb-0  inline-block fs-5 font-medium">Storage rentals </h1>
            <h3 className="fs-3 text-success ">&#8377; {props.quote} <span>/Month</span></h3>
            
            </div>
            <div className="col-md-6">
            <h1 className="font-serif text-center text-lg  mt-5 mb-0 inline-block fs-5 font-medium">Assistance Charges </h1>
            <h3 className="fs-3 text-success ">&#8377; {props.SubTotal}</h3>
            </div>

            <div>
              <button onClick={handleGeneratePDF}>Download PDF</button>
              </div>

            <hr />
            <p>
            We have sent details estimation to your registered email id. Kindly confirm your storage with a response to email along with your pick up address and ID proof
              
            </p>
            <p> To help you with your storage request, we have designated a dedicated specialized relationship manager,  Amit <span className="text-success fw-3">+91 9866012094</span></p>
            <p>If you have any questions about this estimation, send email to contact@genxrelocator.com or reach out to your relationship manager for assistance.</p>
            <br />
            <p>Looking forward to serve you</p>
            <br />
           
            <p>Best Regards,</p>
            <p>The GenX Team</p>
          
            
          </div>
          <m.section className="pt-[60px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] xs:px-0" {...fadeIn}>
       
        <Container>
          <Row className="justify-center">
            <Col className="text-center">
              <Buttons onClick={(e)=>{ e.preventDefault();  handleShow1(true)}} ariaLabel="button" href="#" className="mx-[10px] font-medium font-serif rounded-none uppercase btn-flat" size="xl" themeColor={["#502970", "#f34259", "#502970"]} color="#fff" title="Book A Slot" />
              <Modal show={quoteShow} fullscreen={quoteFullscreen} >
        
        <Modal.Body  >{/* <span aria-hidden="true" id="close_span" class="float-right fs-1 fw-1 p-5 m-5">×</span> */}
      
      <MoveInPage newid = {props.newid}/>
        </Modal.Body>
      </Modal>
            </Col>
          </Row>
        </Container>
      </m.section>
        </div>
        </Col>
      </Row>
    </Container>
  </m.section>

  





</div>

);
      }
         

export default QuotationPage;