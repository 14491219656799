// Import necessary React components and styles
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Navbar, OverlayTrigger, Tooltip ,ThemeProvider, Modal } from "react-bootstrap";
import Header, { HeaderNav } from "../../../Components/Header/Header";
import Buttons from "../../../Components/Button/Buttons";
import ReviewPage from "./ReviewPage"
import Appliences from "./AppliencesPage";
import axios from "axios";
import ClosePopup from "../ClosePopup";

const Boxes = (props) => {

  const [packFullscreen, setPackFullscreen] = useState(true);
  const [packShow, setPackShow] = useState(false);
  const [box,setBox] = useState({
    boxes:0,
    luggage:0
  })

  const readValue = async(e)=>{
    const {name,value} = e.target
    setBox({...box,[name]:value})
    }
  
  
  const [fullscreen, setFullscreen] = useState(true);
const [show, setShow] = useState(false);
const [showModal, setShowModal] = useState(false);
const handleCloseModal = () => setShowModal(false);
const handleShowModal = () => setShowModal(true);

const handleShow1 = async(breakpoint)=>{
 
  try {
    console.log(box);
    
    
    await axios.patch(`${process.env.REACT_APP_API}/api/member/boxes/${props.newID}`, box)
    .then(res=>{
       console.log(res.data);
    })
    .catch(err=>{
    console.log(err.message)
    })
    } catch (error) {
    console.log(error.message)
    }

  setPackFullscreen(breakpoint);
  setPackShow(true);
  }

const handleShow2 = async(breakpoint)=>{
 

  setFullscreen(breakpoint);
  setShow(true);
  }


  const handleKeyDown = (e, name) => {
    if (e.key === "ArrowUp") {
      e.preventDefault();
      incrementValue(name);
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      decrementValue(name);
    }
  };

  const incrementValue = (name) => {
    setBox({
      ...box,
      [name]: parseInt(box[name]) + 1 || 1
    });
  };
  
  const decrementValue = (name) => {
    const currentValue = parseInt(box[name]);
    if (currentValue > 0) {
      setBox({
        ...box,
        [name]: currentValue - 1
      });
    }
  };
  const handleToggleModal = () => setShowModal(prev => !prev);


  return (
    <ThemeProvider>
    <div style={props.style}>
       {/* Header Start*/}
  <Header topSpace={{ md: true }} type="reverse-scroll">
    <HeaderNav fluid="fluid" theme="dark" expand="lg"
      className="py-[40px]  px-[35px] md:px-[15px] md:py-[20px] sm:px-0">
      <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
      <Link aria-label="header logo" className="flex items-center" to="/">
      <Navbar.Brand className="inline-block p-0 m-0">
        <img className="default-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/genx-logo.png' alt='logo' />
        <img className="alt-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/genx-logo.png' alt='logo' />
        <img className="mobile-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/genx-logo.png' alt='logo' />
      </Navbar.Brand>
      </Link>
      </Col>

    </HeaderNav>
    <span onClick={handleToggleModal} className="cursor-pointer float-right pe-[50px] me-[50px] text-dark xs:me-[0px] " style={{ fontSize: '20px' }}>✖</span>
    <Modal show={showModal} onHide={handleCloseModal} centered size="md">
        
        <Modal.Body>
          {/* Your long popup content */}
          {/* You can render the InstaQuote2 component here or any other content */}
         
        </Modal.Body>
        <ClosePopup/>
        {/* You can add a footer if needed */}
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
  </Header>
  {/* Header Start*/}

      <Col xl={{ span: 8, offset: 2 }} md={12} className="lg:px-[30px] md:px-[15px]">
        <div className="m-20 p-20 xs:p-5 xs:m-5 xs:p-0 xs:mx-2 xs:mt-40">
          <h6 className="font-serif font-medium text-darkgray fs-4 ms-3">
            How many boxes/luggages do you need to store?
          </h6>

          {/* Large Furniture Input */}
          <div className="form-group p-2 m-3 xs:p-0 xs:m-0">
            <label className="text-dark font-medium fs-5 mx-1 custom-label-style">
            Number of boxes
              {/* <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="tooltip-large">Boxes(2ft x 1.5ft x 1.5ft) are used to store kitchen utensils, clothes, bedsheets, pillows, Shoes etc.1BHK requires about 15 boxes
                2BHK requires about 25 boxes
                3BHK requires about 35 boxes            </Tooltip>}
              >
                <span className="ml-2 text-primary">(?)</span>
              </OverlayTrigger> */}
            </label> <br />
            <span className="mx-1">Boxes(2ft x 1.5ft x 1.5ft) are used to store bedsheets, clothes, kitchen utensils,  pillows, Shoes, etc. <br />
            <span className="text-[#e8a626]">1BHK</span> requires about <span className="text-[#2ed47c]">15 boxes</span> <br />
            <span className="text-[#e8a626]">2BHK</span> requires about <span className="text-[#2ed47c]">25 boxes</span> <br />
            <span className="text-[#e8a626]">3BHK</span> requires about <span className="text-[#2ed47c]">35 boxes</span> </span>
           <div className="input-group">
           <input
              type="number"
              className="form-control custom-input-style"
              id="boxes"
              name="boxes"
              value={box.boxes}
              onChange={readValue}
              onKeyDown={(e) => handleKeyDown(e, "boxes")}
            />
               <div className="input-group-append">
      <span className="input-group-text" onClick={() => incrementValue("boxes")}>
      +</span>
      
    </div>
    <div className="input-group-append">
    <span className="input-group-text" onClick={() => decrementValue("boxes")}>
       -
      </span>
    </div>
           </div>
          </div>
          <div className="form-group p-2 m-3 xs:p-0 xs:m-0">
            <label className="text-dark font-medium fs-5 mx-1 custom-label-style">
            Number of luggages
              
            </label>
           <div className="input-group">
           <input
              type="number"
              className="form-control custom-input-style"
              id="luggage"
              name="luggage"
              value={box.luggage}
              onChange={readValue}
              onKeyDown={(e) => handleKeyDown(e, "luggage")}
            />
               <div className="input-group-append">
      <span className="input-group-text" onClick={() => incrementValue("luggage")}>
      +</span>
      
    </div>
    <div className="input-group-append">
    <span className="input-group-text" onClick={() => decrementValue("luggage")}>
       -
      </span>
    </div>
           </div>
          </div>
          

          {/* Medium Furniture Input */}
          {/* ... (Repeat the same structure for medium and small inputs) ... */}

          {/* Continue Button */}
         <div className="btn-group d-flex justify-content-between">
         <div className="form-button">
            <Buttons onClick={(e)=>{handleShow2(true)}}
              className="font-medium font-serif  uppercase rounded-none xs:m-0"
              themeColor={["#502970", "#f34259", "#502970"]}
              size="lg"
              color="#fff"
              title="Previous"
            />
            <Modal show={show} fullscreen={fullscreen} >
        
        <Modal.Body  >{/* <span aria-hidden="true" id="close_span" class="float-right fs-1 fw-1 p-5 m-5">×</span> */}
      
       <Appliences />
        </Modal.Body>
      </Modal>
          </div>
          <div className="form-button">
            <Buttons onClick={(e)=>{handleShow1(true)}}
              className="font-medium font-serif  uppercase rounded-none xs:m-0"
              themeColor={["#502970", "#f34259", "#502970"]}
              size="lg"
              color="#fff"
              title="Continue"
            />
             <Modal show={packShow} fullscreen={packFullscreen} >
        
        <Modal.Body  >{/* <span aria-hidden="true" id="close_span" class="float-right fs-1 fw-1 p-5 m-5">×</span> */}
        <ReviewPage newId = {props.newID} />
        </Modal.Body>
      </Modal>
          </div>
          
         </div>
        </div>
      </Col>
    </div>
    </ThemeProvider>
  );
};

export default Boxes;
