// Import necessary React components and styles
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Navbar, OverlayTrigger, Tooltip ,ThemeProvider, Modal } from "react-bootstrap";
import Header, { HeaderNav } from "../../../Components/Header/Header";
import Buttons from "../../../Components/Button/Buttons";
import Appliences from "../household/AppliencesPage";
import axios from "axios";
import Move from "./MovePage";
import ClosePopup from "../ClosePopup";

const Household = (props) => {


  const [fullscreen, setFullscreen] = useState(true);
const [show, setShow] = useState(false);
const [fFullscreen, setFFullscreen] = useState(true);
const [fShow, setFShow] = useState(false);
const [furniture,setFurniture] = useState({
  fLarge:0,
  fMedium:0,
  fSmall:0
})
const [showModal, setShowModal] = useState(false);
const handleCloseModal = () => setShowModal(false);
const handleShowModal = () => setShowModal(true);
const readValue = async(e)=>{
  const {name,value} = e.target
  setFurniture({...furniture,[name]:value})
  }

const handleShow = async(breakpoint)=>{
  setFullscreen(breakpoint);
  setShow(true);
  try {
    console.log(furniture);
    
    
    await axios.patch(`${process.env.REACT_APP_API}/api/member/furniture/${props.newID}`, furniture)
    .then(res=>{
       console.log(res.data);
    })
    .catch(err=>{
    console.log(err.message)
    })
    } catch (error) {
    console.log(error.message)
    }
  }
  const handleShow2 = async(breakpoint)=>{
 

    setFFullscreen(breakpoint);
    setFShow(true);
  
    }

   const handleKeyDown = (e, name) => {
    if (e.key === "ArrowUp") {
      e.preventDefault();
      incrementValue(name);
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      decrementValue(name);
    }
  };
  const incrementValue = (name) => {
    setFurniture({
      ...furniture,
      [name]: parseInt(furniture[name]) + 1 || 1
    });
  };
  
  const decrementValue = (name) => {
    const currentValue = parseInt(furniture[name]);
    if (currentValue > 0) {
      setFurniture({
        ...furniture,
        [name]: currentValue - 1
      });
    }
 
  };
  const handleToggleModal = () => setShowModal(prev => !prev);
  return (
    
    <div style={props.style}>
      {/* Header Start*/}
  <Header topSpace={{ md: true }} type="reverse-scroll">
    <HeaderNav fluid="fluid" theme="dark" expand="lg"
      className="py-[40px]  px-[35px] md:px-[15px] md:py-[20px] sm:px-0">
      <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
      <Link aria-label="header logo" className="flex items-center" to="/">
      <Navbar.Brand className="inline-block p-0 m-0">
        <img className="default-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-white@2x.webp' alt='logo' />
        <img className="alt-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-green-dark@2x.webp' alt='logo' />
        <img className="mobile-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-black@2x.webp' alt='logo' />
      </Navbar.Brand>
      </Link>
      </Col>

    </HeaderNav>
    <span onClick={handleToggleModal} className="cursor-pointer float-right pe-[50px] me-[50px] text-dark xs:me-[0px] " style={{ fontSize: '20px' }}>✖</span>
    <Modal show={showModal} onHide={handleCloseModal} centered size="md">
        
        <Modal.Body>
          {/* Your long popup content */}
          {/* You can render the InstaQuote2 component here or any other content */}
         
        </Modal.Body>
        <ClosePopup/>
        {/* You can add a footer if needed */}
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
  </Header>
  {/* Header Start*/}

      <Col xl={{ span: 8, offset: 2 }} md={12} className="lg:px-[30px] md:px-[15px]">
        <div className="m-20 p-20 xs:p-5 xs:m-5 xs:p-0 xs:mx-2 xs:mt-40 " >
          <h6 className="font-serif font-medium fs-4 text-darkgray ms-3">
            How many furnitures do you need to store?
          </h6>

          {/* Large Furniture Input */}
          <div className="form-group p-2 m-3 xs:p-0 xs:m-0  ">
            <label className="text-dark font-medium fs-5  custom-label-style">
              Number of large furnitures
              {/* <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="tooltip-large">Ex: Cot, Mattress, Cupboard, Almariah, Diwan, Sofa, Seater, Dining-Table, Dressing-Table</Tooltip>}
              >
                <span className="ml-2 text-primary">(?)</span>
              </OverlayTrigger> */}
            </label><br />
            <span>  Almariah, Cot, Cupboard, Diwan, Dining-Table, Dressing-Table, Mattress, Sofa, Seater, etc.</span>
            <div className="input-group">
            <input
              type="number"
              className="form-control custom-input-style"
              id="fLarge"
              name="fLarge"
              value={furniture.fLarge}
              onChange={readValue}
              onKeyDown={(e) => handleKeyDown(e, "fLarge")}
            />

<div className="input-group-append">
      <span className="input-group-text" onClick={() => incrementValue("fLarge")}>
      +</span>
      
    </div>
    <div className="input-group-append">
    <span className="input-group-text" onClick={() => decrementValue("fLarge")}>
       -
      </span>
    </div>
            </div>
            
            

            
          </div>
          <div className="form-group p-2 m-3 xs:p-0 xs:m-0">
            <label className="text-dark font-medium fs-5 custom-label-style">
              Number of medium furnitures
              
              {/* <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="tooltip-large">Dining-Chairs, Side-Table, Center-table, Office-Chair, Small-Cabinet, Small-cupboard, Foldable-Mattress</Tooltip>}
              >
                <span className="ml-2 text-primary">(?)</span>
              </OverlayTrigger> */}
            </label><br />
            <span>Center-table, Dining-Chairs, Foldable-Mattress, Office-Chair, Small-Cabinet, Small-cupboard,Side-Table, etc. </span>
            <div className="input-group">
            <input
              type="number"
              className="form-control custom-input-style"
              id="fMedium"
              name="fMedium"
              value={furniture.fMedium}
              onChange={readValue}
              onKeyDown={(e) => handleKeyDown(e, "fMedium")}
            />
            <div className="input-group-append">
      <span className="input-group-text" onClick={() => incrementValue("fMedium")}>
      +</span>
      
    </div>
    <div className="input-group-append">
    <span className="input-group-text" onClick={() => decrementValue("fMedium")}>
       -
      </span>
    </div>
            </div>
          </div>
          <div className="form-group p-2 m-3 xs:p-0 xs:m-0">
            <label className="text-dark font-medium fs-5 custom-label-style">
              Number of small furnitures
              {/* <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="tooltip-large">Ex: Miscellaneous items like Buckets, Plastic-Chairs, Stands, Small-Show-Stands, Planks</Tooltip>}
              >
                <span className="ml-2 text-primary">(?)</span>
              </OverlayTrigger> */}
            </label><br />
            <span id="tooltip-large">Miscellaneous items like Buckets, Planks, Plastic-Chairs, Stands, Small-Show-Stands, etc. </span>
           <div className="input-group">
           <input
            type="number"
            className="form-control custom-input-style"
            id="fLarge"
            name="fLarge"
            value={furniture.fSmall}
            onChange={readValue}
            onKeyDown={(e) => handleKeyDown(e, "fSmall")}
          />
          <div className="input-group-append">
      <span className="input-group-text" onClick={() => incrementValue("fSmall")}>
      +</span>
      
    </div>
    <div className="input-group-append">
    <span className="input-group-text" onClick={() => decrementValue("fSmall")}>
       -
      </span>
    </div>
           </div>
          </div>



          {/* Medium Furniture Input */}
          {/* ... (Repeat the same structure for medium and small inputs) ... */}

          {/* Continue Button */}
          <div className="btn-group d-flex justify-content-between">
          <div className="form-button">
            <Buttons onClick={(e)=>{handleShow2(true)}}
              className="font-medium font-serif  uppercase rounded-none xs:m-0"
              themeColor={["#502970", "#f34259", "#502970"]}
              size="lg"
              color="#fff"
              title="Previous"
            />
            <Modal show={fShow} fullscreen={fFullscreen} >
        
        <Modal.Body  >{/* <span aria-hidden="true" id="close_span" class="float-right fs-1 fw-1 p-5 m-5">×</span> */}
      
       <Move/>
        </Modal.Body>
      </Modal>
          </div>
          <div className="form-button">
            <Buttons onClick={(e)=>{handleShow(true)}}
              className="font-medium font-serif  uppercase rounded-none xs:m-0"
              themeColor={["#502970", "#f34259", "#502970"]}
              size="lg"
              color="#fff"
              title="Continue"
            />

<Modal show={show} fullscreen={fullscreen} >
        
        <Modal.Body  >{/* <span aria-hidden="true" id="close_span" class="float-right fs-1 fw-1 p-5 m-5">×</span> */}
        <Appliences newID={props.newID}/>
        </Modal.Body>
      </Modal>
          </div>
          
          </div>
        </div>
      </Col>
    </div>
   
  );
};

export default Household;
