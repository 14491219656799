// Import necessary React components and styles
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Navbar, OverlayTrigger, Tooltip ,ThemeProvider, Modal } from "react-bootstrap";
import Header, { HeaderNav } from "../../../Components/Header/Header";
import Buttons from "../../../Components/Button/Buttons";
import Household from "./HouseholdPage";
import Boxes from "./BoxesPage"
import axios from "axios";
import ClosePopup from "../ClosePopup";

const Appliences = (props) => {
  const [boxesFullscreen, setBoxesFullscreen] = useState(true);
  const [boxesShow, setBoxesShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
const handleCloseModal = () => setShowModal(false);
const handleShowModal = () => setShowModal(true);

  
  const [fullscreen, setFullscreen] = useState(true);
const [show, setShow] = useState(false);
const [appliances,setAppliances] = useState({
  aLarge:0,
  aMedium:0,
  aSmall:0
})


const readValue = async(e)=>{
  const {name,value} = e.target
  setAppliances({...appliances,[name]:value})
  }



const handleShow1 = async(breakpoint)=>{
 

  setBoxesFullscreen(breakpoint);
  setBoxesShow(true);
  
  try {
    console.log(appliances);
    
    
    await axios.patch(`${process.env.REACT_APP_API}/api/member/appliances/${props.newID}`, appliances)
    .then(res=>{
       console.log(res.data);
    })
    .catch(err=>{
    console.log(err.message)
    })
    } catch (error) {
    console.log(error.message)
    }
  }

const handleShow2 = async(breakpoint)=>{
 

  setFullscreen(breakpoint);
  setShow(true);

  }

  const handleKeyDown = (e, name) => {
    if (e.key === "ArrowUp") {
      e.preventDefault();
      incrementValue(name);
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      decrementValue(name);
    }
  };

  const incrementValue = (name) => {
    setAppliances({
      ...appliances,
      [name]: parseInt(appliances[name]) + 1 || 1
    });
  };
  
  const decrementValue = (name) => {
    const currentValue = parseInt(appliances[name]);
    if (currentValue > 0) {
      setAppliances({
        ...appliances,
        [name]: currentValue - 1
      });
    }
  };

  const handleToggleModal = () => setShowModal(prev => !prev);
  

  return (
    <ThemeProvider>
    <div style={props.style}>
      {/* Header Start*/}
  <Header topSpace={{ md: true }} type="reverse-scroll">
    <HeaderNav fluid="fluid" theme="dark" expand="lg"
      className="py-[40px]  px-[35px] md:px-[15px] md:py-[20px] sm:px-0">
      <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
      <Link aria-label="header logo" className="flex items-center" to="/">
      <Navbar.Brand className="inline-block p-0 m-0">
        <img className="default-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-white@2x.webp' alt='logo' />
        <img className="alt-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-green-dark@2x.webp' alt='logo' />
        <img className="mobile-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-black@2x.webp' alt='logo' />
      </Navbar.Brand>
      </Link>
      </Col>

    </HeaderNav>
    <span onClick={handleToggleModal} className="cursor-pointer float-right pe-[50px] me-[50px] text-dark xs:me-[0px] " style={{ fontSize: '20px' }}>✖</span>
    <Modal show={showModal} onHide={handleCloseModal} centered size="md">
        
        <Modal.Body>
          {/* Your long popup content */}
          {/* You can render the InstaQuote2 component here or any other content */}
         
        </Modal.Body>
        <ClosePopup/>
        {/* You can add a footer if needed */}
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
  </Header>
  {/* Header Start*/}

      <Col xl={{ span: 8, offset: 2 }} md={12} className="lg:px-[30px] md:px-[15px]">
        <div className="m-20 p-20 xs:p-5 xs:m-5 xs:p-0 xs:mx-2 xs:mt-40">
          <h6 className="font-serif font-medium fs-4 text-darkgray ms-3">
             How many appliances do you need to store?
          </h6>

          {/* Large Furniture Input */}
          <div className="form-group p-2 m-3 xs:p-0 xs:m-0">
            <label className="text-dark font-medium fs-5  custom-label-style">
              Number of large appliances
              {/* <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="tooltip-large">Ex:Fridge, Washing Machine, AC Outdoor, AC indoor, TV, Monitor, Dish Washer etc.
            </Tooltip>}
              >
                <span className="ml-2 text-primary">(?)</span>
              </OverlayTrigger> */}
            </label><br />
            <span>AC Outdoor, AC indoor,Dish Washer, Fridge, Monitor, TV, Washing Machine, etc.</span>
           <div className="input-group">
           <input
              type="number"
              className="form-control custom-input-style"
              id="aLarge"
              name="aLarge"
              value={appliances.aLarge}
              onChange={readValue}
              onKeyDown={(e) => handleKeyDown(e, "aLarge")}
            />
            <div className="input-group-append">
      <span className="input-group-text" onClick={() => incrementValue("aLarge")}>
      +</span>
      
    </div>
    <div className="input-group-append">
    <span className="input-group-text" onClick={() => decrementValue("aLarge")}>
       -
      </span>
    </div>
           </div>
          </div>
          <div className="form-group m-3 p-2 xs:p-0 xs:m-0">
            <label className="text-dark font-medium fs-5  custom-label-style">
              Number of medium appliances
              {/* <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="tooltip-large">
                Water Purifier, Air Cooler, Table Fan, Music System etc.,</Tooltip>}
              >
                <span className="ml-2 text-primary">(?)</span>
              </OverlayTrigger> */}
            </label><br />
            <span>Air Cooler, Music System, Water Purifier, Table Fan etc.,</span>
            <div className="input-group">
            <input
              type="number"
              className="form-control custom-input-style"
              id="aMedium"
              name="aMedium"
              value={appliances.aMedium}
              onChange={readValue}
              onKeyDown={(e) => handleKeyDown(e, "aMedium")}
            />
            <div className="input-group-append">
      <span className="input-group-text" onClick={() => incrementValue("aMedium")}>
      +</span>
      
    </div>
    <div className="input-group-append">
    <span className="input-group-text" onClick={() => decrementValue("aMedium")}>
       -
      </span>
    </div>
            </div>
          </div>
          <div className="form-group p-2 m-3 xs:p-0 xs:m-0">
            <label className="text-dark font-medium fs-5 custom-label-style">
              Number of small appliances
              {/* <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="tooltip-large">Ex: Microwave, Gas Stove, Induction stove, Ceiling Fan, Grinder, Cooker, Toaster, Grills etc. </Tooltip>}
              >
                <span className="ml-2 text-primary">(?)</span>
              </OverlayTrigger> */}
            </label><br />
            <span>Ceiling Fan, Cooker, Gas Stove, Grinder, Grills, Induction stove, Microwave,  Toaster, etc.</span>
            <div className="input-group">
            <input
              type="number"
              className="form-control custom-input-style"
              id="aSmall"
              name="aSmall"
              value={appliances.aSmall}
              onChange={readValue}
              onKeyDown={(e) => handleKeyDown(e, "aSmall")}
            />
            <div className="input-group-append">
      <span className="input-group-text" onClick={() => incrementValue("aSmall")}>
      +</span>
      
    </div>
    <div className="input-group-append">
    <span className="input-group-text" onClick={() => decrementValue("aSmall")}>
       -
      </span>
    </div>
            </div>
          </div>



          {/* Medium Furniture Input */}
          {/* ... (Repeat the same structure for medium and small inputs) ... */}

          {/* Continue Button */}
          <div className="btn-group d-flex justify-content-between">
          <div className="form-button">
            <Buttons onClick={(e)=>{handleShow2(true)}}
              className="font-medium font-serif  uppercase rounded-none xs:m-0"
              themeColor={["#502970", "#f34259", "#502970"]}
              size="lg"
              color="#fff"
              title="Previous"
            />
            <Modal show={show} fullscreen={fullscreen} >
        
        <Modal.Body  >{/* <span aria-hidden="true" id="close_span" class="float-right fs-1 fw-1 p-5 m-5">×</span> */}
      
       <Household/>
        </Modal.Body>
      </Modal>
          </div>
          <div className="form-button">
            <Buttons onClick={(e)=>{handleShow1(true)}}
              className="font-medium font-serif  uppercase rounded-none xs:m-0"
              themeColor={["#502970", "#f34259", "#502970"]}
              size="lg"
              color="#fff"
              title="Continue"
            />
             <Modal show={boxesShow} fullscreen={boxesFullscreen} >
        
        <Modal.Body  >{/* <span aria-hidden="true" id="close_span" class="float-right fs-1 fw-1 p-5 m-5">×</span> */}
        <Boxes newID={props.newID}/>
        </Modal.Body>
      </Modal>
          </div>
          </div>
         
        </div>
      </Col>
    </div>
    </ThemeProvider>
  );
};

export default Appliences;
