const TeamData01 = [
    {
        img: './assets/img/webp/amith-gupta.png',
        name: 'AMIT KUMAR GUPTA',
        designation: 'Director',
        // social_links: [
        //     {
        //         title: 'facebook',
        //         link: "https://www.facebook.com",
        //         icon: "fab fa-facebook-f"
        //     },
        //     {
        //         title: 'instagram',
        //         link: "https://www.instagram.com",
        //         icon: "fa-brands fa-instagram"
        //     },
        //     {
        //         title: 'twitter',
        //         link: "https://www.twitter.com",
        //         icon: "fa-brands fa-twitter"
        //     }
        // ]
    },
    {
        img: './assets/img/webp/raj-genx.png',
        name: ' RAJENDER SINGH YADAV',
        designation: 'Director',
        // social_links: [
        //     {
        //         title: 'facebook',
        //         link: "https://www.facebook.com",
        //         icon: "fab fa-facebook-f"
        //     },
        //     {
        //         title: 'instagram',
        //         link: "https://www.instagram.com",
        //         icon: "fa-brands fa-instagram"
        //     },
        //     {
        //         title: 'twitter',
        //         link: "https://www.twitter.com",
        //         icon: "fa-brands fa-twitter"
        //     }
        // ]
    }
    // ,
    // {
    //     img: 'https://via.placeholder.com/525x639',
    //     name: 'Jemmy Watson',
    //     designation: 'DESIGNER',
    //     social_links: [
    //         {
    //             title: 'facebook',
    //             link: "https://www.facebook.com",
    //             icon: "fab fa-facebook-f"
    //         },
    //         {
    //             title: 'instagram',
    //             link: "https://www.instagram.com",
    //             icon: "fa-brands fa-instagram"
    //         },
    //         {
    //             title: 'twitter',
    //             link: "https://www.twitter.com",
    //             icon: "fa-brands fa-twitter"
    //         }
    //     ]
    // },
    // {
    //     img: 'https://via.placeholder.com/525x639',
    //     name: 'Jeremy Dupont',
    //     designation: 'MANAGER',
    //     social_links: [
    //         {
    //             title: 'facebook',
    //             link: "https://www.facebook.com",
    //             icon: "fab fa-facebook-f"
    //         },
    //         {
    //             title: 'instagram',
    //             link: "https://www.instagram.com",
    //             icon: "fa-brands fa-instagram"
    //         },
    //         {
    //             title: 'twitter',
    //             link: "https://www.twitter.com",
    //             icon: "fa-brands fa-twitter"
    //         }
    //     ]
    // }
]

const TeamData02 = [
    {
        img: 'https://via.placeholder.com/721x902',
        name: 'Alexander Harvard',
        designation: 'Operations officer',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
        ]
    },
    {
        img: 'https://via.placeholder.com/721x902',
        name: 'Bryan Jonhson',
        designation: 'Graphic designer',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            }
        ]
    },
    {
        img: 'https://via.placeholder.com/721x902',
        name: 'Jeremy Dupont',
        designation: 'Web developer',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            }
        ]
    }
]

const TeamData03 = [
    {
        img: 'https://via.placeholder.com/375x460',
        name: 'Alexander Harvard',
        designation: 'GENERALIST CONSULTANT',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            }
        ]
    },
    {
        img: 'https://via.placeholder.com/375x460',
        name: 'Bryan Jonhson',
        designation: 'SPECIALIST CONSULTANT',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            }
        ]
    },
    {
        img: 'https://via.placeholder.com/375x460',
        name: 'Jemmy Watson',
        designation: 'FINANCIAL CONSULTANT',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            }
        ]
    },
    {
        img: 'https://via.placeholder.com/375x460',
        name: 'Jeremy Dupont',
        designation: 'BUSINESS CONSULTANT',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            }
        ]
    }
]

const TeamData04 = [
    {
        img: 'https://via.placeholder.com/800x1000',
        name: 'JEMMY WATSON',
        designation: 'GRAPHIC DESIGNER',
        title: 'I AM CREATIVE',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
            {
                title: 'dribbble',
                link: "https://www.dribbble.com",
                icon: "fa-brands fa-dribbble"
            },
            {
                title: 'linkedin',
                link: "https://www.linkedin.com",
                icon: "fa-brands fa-linkedin-in"
            }
        ]
    },
    {
        img: 'https://via.placeholder.com/800x1000',
        name: 'JEREMY DUPONT',
        designation: 'WEB DEVELOPER',
        title: 'I AM TRENDY',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
            {
                title: 'dribbble',
                link: "https://www.dribbble.com",
                icon: "fa-brands fa-dribbble"
            },
            {
                title: 'linkedin',
                link: "https://www.linkedin.com",
                icon: "fa-brands fa-linkedin-in"
            }
        ]
    },
    {
        img: 'https://via.placeholder.com/800x1000',
        name: 'BRYAN JONHSON',
        designation: 'OPERATIONS OFFICER',
        title: 'I AM PANCTUAL',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
            {
                title: 'dribbble',
                link: "https://www.dribbble.com",
                icon: "fa-brands fa-dribbble"
            },
            {
                title: 'linkedin',
                link: "https://www.linkedin.com",
                icon: "fa-brands fa-linkedin-in"
            }
        ]
    }
]

const TeamData05 = [
    {
        img: 'https://via.placeholder.com/800x1005',
        name: 'JEMMY WATSON',
        designation: 'BOOTCAMP TRAINER',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
        ]
    },
    {
        img: 'https://via.placeholder.com/800x1005',
        name: 'BRYAN JONHSON',
        designation: 'CROSSFIT TRAINER',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
        ]
    },
    {
        img: 'https://via.placeholder.com/800x1005',
        name: 'JEREMY DUPONT',
        designation: 'SPINNING TRAINER',
        social_links: [
            {
                title: 'facebook',
                link: "https://www.facebook.com",
                icon: "fab fa-facebook-f"
            },
            {
                title: 'instagram',
                link: "https://www.instagram.com",
                icon: "fa-brands fa-instagram"
            },
            {
                title: 'twitter',
                link: "https://www.twitter.com",
                icon: "fa-brands fa-twitter"
            },
        ]
    },
]

export { TeamData01, TeamData02, TeamData03, TeamData04, TeamData05 }