import React, { useCallback, useState } from "react";

// Libraries
import { Button, Card, Col, Container, Navbar,Modal, Row, Toast } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Parallax } from "react-scroll-parallax";
import { Formik, Form } from 'formik';
import { m, AnimatePresence } from 'framer-motion';
import * as Yup from 'yup';

// Component
import Header, { HeaderNav, Menu } from "../../Components/Header/Header";
import { Input } from "../../Components/Form/Form";
import Buttons from "../../Components/Button/Buttons";
import axios from "axios";
import Register from "../InstaQuote/Register"
import InstaQuote from "./InstaQuote";
import ClosePopup from "./ClosePopup";


// Data

const InstaQuote2 = (props) => {
const swiperRef = React.useRef(null);
const navigate = useNavigate();

const params = useParams();



const [storage,setStorage] = useState("")
const [store,setStore]=useState('')

// const readValue = async(e)=>{
// const {name,value} = e.target.value
// setStorage({...storage,[name]:value})
// }
const handleToggleModal = () => setShowModal(prev => !prev);


const [fullscreen, setFullscreen] = useState(true);
const [show, setShow] = useState(false);
const [storageShow, setStorageShow] = useState(false);
const [storageFullscreen, setStorageFullscreen] = useState(true);
const [showModal, setShowModal] = useState(false);
const handleCloseModal = () => setShowModal(false);
const handleShowModal = () => setShowModal(true);





// const handleContinue = async (e) => {
// e.preventDefault();
// try {
// console.log("id",params.id)
// console.log(storage);
// const storageType = storage;
// await axios.patch(`/api/member/storage/${params.id}`,{storage})
// .then(res=>{ 
//   console.log(res.data)
// })
// .catch(err=>{
// console.log(err.message)
// })
// } catch (error) {
// console.error(error.response.data);
// }
// };

const handleShow = async(breakpoint)=> {
  setFullscreen(breakpoint);
    // Check if location is selected
    if (storage === "") {
      alert('Please select a Storage ');
      return;
    }
  setShow(true);
  try {
   // console.log("insta2 id",props.newid)
    //console.log(storage);
    const storageType = storage;
    await axios.patch(`${process.env.REACT_APP_API}/api/member/storage/${props.newid}`,{storage})
    .then(res=>{ 
      console.log(res.data)
      setStore(storageType)
      console.log(storageType)
      
     
     
    
    })
    .catch(err=>{
    console.log(err.message)
    })
    } catch (error) {
    console.error(error.response.data);
    }
}

const handleShow2 = async(breakpoint)=>{
 

  setStorageFullscreen(breakpoint);
  setStorageShow(true);

  }






// await axios.patch(`/api/member/storage/${params._id}`, {storage:storage})
return (
<div style={props.style}>

  {/* Header Start*/}
  <Header topSpace={{ md: true }} type="reverse-scroll">
    <HeaderNav fluid="fluid" theme="dark" expand="lg"
      className="py-[40px]  px-[35px] md:px-[15px] md:py-[20px] sm:px-0">
      <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
      <Link aria-label="header logo" className="flex items-center" to="/">
      <Navbar.Brand className="inline-block p-0 m-0">
        <img className="default-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-white@2x.webp' alt='logo' />
        <img className="alt-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-green-dark@2x.webp' alt='logo' />
        <img className="mobile-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-black@2x.webp' alt='logo' />
      </Navbar.Brand>
      </Link>
      </Col>

    </HeaderNav>
    <span  onClick={handleToggleModal} className="cursor-pointer float-right pe-[50px] me-[50px] text-dark xs:me-[0px] " style={{ fontSize: '20px' }}>✖</span>
    <Modal show={showModal} onHide={handleCloseModal} centered size="md">
        
        <Modal.Body>
          {/* Your long popup content */}
          {/* You can render the InstaQuote2 component here or any other content */}
         
        </Modal.Body>
        <ClosePopup/>
        {/* You can add a footer if needed */}
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
  </Header>
  {/* Header Start*/}
 
  <Col xl={{ span: 8, offset: 1 }} md={12} className="lg:px-[30px] md:px-[15px] ">
  
  <form>
    <div className="m-20 p-20 xs:p-0 xs:mx-2 xs:mt-40  ">
      <h6 className="font-serif font-medium text-darkgray fs-4 ms-3">Which kind of storage space are you looking for ?</h6>

      <div class="form-check p-2 m-3 xs:p-0 xs:m-0  ">
        <input type="radio" class="form-check-input checkbox_label fs-5 m-2  " checked={storage === "Household"}
          onChange={(e)=>{setStorage(e.target.value)}} id="household" name="storage" value={"Household"} required />
        <label class="form-check-label text-dark fs-5 mx-3 font-medium">Household Storage</label>
      </div>

      <div class="form-check  p-2 m-3 xs:p-0 xs:m-0  " >
        <input type="radio" class="form-check-input checkbox_label fs-5  m-2" checked={storage==="Corporate"}
          onChange={(e)=>{setStorage(e.target.value)}} id="corporate" name="storage" value={"Corporate"} required />
        <label class="form-check-label font-medium fs-5 text-dark mx-3">Corporate Storage</label>
      </div>


      <div class="form-check p-2 m-3 xs:p-0 xs:m-0 " >
        <input type="radio" class="form-check-input checkbox_label fs-5  m-2" checked={storage==="Vehicle"}
          onChange={(e)=>{setStorage(e.target.value)}} id="vehicle" name="storage" value={"Vehicle"} required />
        <label class="form-check-label font-medium text-dark fs-5 mx-3">Vehicle (Car/Bike) Storage</label>
      </div>


      <div class="form-check p-2 m-3 xs:p-0 xs:m-0 " >
        <input type="radio" class="form-check-input checkbox_label fs-5 m-2" checked={storage === "Container"}
          onChange={(e)=>{setStorage(e.target.value)}} id="container" name="storage" value={"Container"} />
        <label class="form-check-label text-dark font-medium fs-5 mx-3">Container Rentals </label>
      </div>

      
      <div class="form-check p-2 m-3 xs:p-0 xs:m-0 " >
        <input type="radio" class="form-check-input checkbox_label fs-5 m-2" checked={storage === "Document"}
          onChange={(e)=>{setStorage(e.target.value)}} id="document" name="storage" value={"Document"} />
        <label class="form-check-label text-dark font-medium fs-5 mx-3">Document Storage</label>
      </div>

  


      <div className="btn-group d-flex justify-content-between">
   

   <div className="form-button">
       <Buttons onClick={(e)=>{handleShow2(true);
      }}
         className="font-medium font-serif  btn btn-info uppercase rounded-none xs:m-0"
         themeColor={["#502970", "#f34259", "#502970"]}
         size="sm"
         color="#fff" 
         title="Previous"
       />
       <Modal show={storageShow} fullscreen={storageFullscreen} >
   
   <Modal.Body  >
   
   <InstaQuote newId ={props.newid} />
   </Modal.Body>
   </Modal>
     </div>
   
     <div class="form-button">
   
   <Buttons onClick={() => handleShow(true)} className="font-medium font-serif xs:m-0 uppercase rounded-none"
    themeColor={["#502970", "#f34259", "#502970"]} size="sm" color="#fff" title="Continue" />
    
       <Modal show={show} fullscreen={fullscreen} >
   
   <Modal.Body  >
     <Register newId ={props.newid} newStorage = {store}/>
   </Modal.Body>
   </Modal>
   </div>
        </div>

    </div>
  </form>
  </Col>





</div>
);
};

export default InstaQuote2;