import React, { useState } from "react";

// Libraries
import { Button, Card, Col, Container, Modal, Navbar, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Parallax } from "react-scroll-parallax";
import { Formik, Form } from 'formik';
import { m, AnimatePresence } from 'framer-motion';
import * as Yup from 'yup';

// Component
import Header, { HeaderNav, Menu } from "../../../Components/Header/Header";
import { Input } from "../../../Components/Form/Form";
import Buttons from "../../../Components/Button/Buttons";
import axios from "axios";
import AboutContactPage from "../../InstaQuote/household/AboutContactPage"
import ClosePopup from "../ClosePopup";

import { useFormik } from 'formik';

// Data

const MoveInPage = (props) => {
const swiperRef = React.useRef(null);
const navigate = useNavigate() 





const [date,setDate] = useState("")
const [time, setTime] = useState("sel");
const [fullscreen, setFullscreen] = useState(true);
const [show, setShow] = useState(false);
const [id,setID]=useState(null)
const [showModal, setShowModal] = useState(false);
const handleCloseModal = () => setShowModal(false);
const handleShowModal = () => setShowModal(true);

const readValue = (e)=>{

}



const handleToggleModal = () => setShowModal(prev => !prev);

const handleShow = async(breakpoint)=>{
     setFullscreen(breakpoint);
     setShow(true)
    console.log(date);
    console.log(time);
    console.log(props.newid)
    await axios.patch(`${process.env.REACT_APP_API}/api/member/movein/${props.newid}`, {date:date,time:time})
    .then(res=>{
      console.log(res.data)
    })
    
    }
// date
const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);



return (
<div style={props.style}>

  {/* Header Start*/}
  <Header topSpace={{ md: true }} type="reverse-scroll">
    <HeaderNav fluid="fluid" theme="dark" expand="lg"
      className="py-[40px]  px-[35px] md:px-[15px] md:py-[20px] sm:px-0">
      <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
      <Link aria-label="header logo" className="flex items-center" to="/">
      <Navbar.Brand className="inline-block p-0 m-0">
        <img className="default-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-white@2x.webp' alt='logo' />
        <img className="alt-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-green-dark@2x.webp' alt='logo' />
        <img className="mobile-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-black@2x.webp' alt='logo' />
      </Navbar.Brand>
      </Link>
      </Col>

    </HeaderNav>
    <span onClick={handleToggleModal} className="cursor-pointer float-right pe-[50px] me-[50px] text-dark xs:me-[0px] " style={{ fontSize: '20px' }}>✖</span>
    <Modal show={showModal} onHide={handleCloseModal} centered size="md">
        
        <Modal.Body>
          {/* Your long popup content */}
          {/* You can render the InstaQuote2 component here or any other content */}
         
        </Modal.Body>
        <ClosePopup/>
        {/* You can add a footer if needed */}
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
  </Header>
  {/* Header Start*/}


  <Col xl={{ span: 8, offset: 1 }} md={12} className="lg:px-[30px] md:px-[15px] ">

 
<div className="form-row m-20 p-20 xs:p-5 xs:m-5 xs:p-0 xs:mx-2 xs:mt-40    ">
 <h6 className="font-serif font-medium text-darkgray fs-4 ms-3">Where would you like to relocate ?</h6>

 
 <div class=" col-md-6  p-2 m-3 xs:p-0 xs:m-0 ">
     <input type="date" className="form-control digits"id="date" name="date" value={date.date}  min={tomorrow.toISOString().split('T')[0]} onChange={(e)=>{setDate(e.target.value)}} />
 </div>

 <div class=" col-md-6  p-2 m-3 xs:p-0 xs:m-0 ">
     <select name="time" id="time" className="form-select form-select-padding-4 "   onChange={(e)=>{setTime(e.target.value)}}  value={time.time}>
     <option value="sel" >select time slot</option>
      <option value="9AM-10AM">9AM-10AM</option>
      <option value="10AM-11AM">10AM-11AM</option>
      <option value="11AM-12PM">11AM-12PM</option>
      <option value="12PM-1PM">12PM-1PM</option>
      <option value="1PM-2PM">1PM-2PM</option>
      <option value="2PM-3PM">2PM-3PM</option>
      <option value="3PM-4PM">3PM-4PM</option>
      <option value="4PM-5PM">4PM-5PM</option>
      <option value="5PM-6PM">5PM-6PM</option>
     </select>
 </div>

 

   
  




   <div class="form-button">
    
     <Buttons onClick={(e)=>{ e.preventDefault();
            handleShow(true)}}  className="font-medium font-serif float-right uppercase rounded-none" themeColor={["#502970", "#f34259", "#502970"]} size="lg" color="#fff" title="Continue" />
                            <Modal show={show} fullscreen={fullscreen} >
       
       <Modal.Body  >{/* <span aria-hidden="true" id="close_span" class="float-right fs-1 fw-1 p-5 m-5">×</span> */}
       <AboutContactPage newid = {props.newid}/>
       </Modal.Body>
     </Modal>

               
     
   </div>

 </div>

 </Col>
 





</div>
);
};

export default MoveInPage;