import React, { useState } from "react";

// Libraries
import { Button, Card, Col, Container, Modal, Navbar, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Parallax } from "react-scroll-parallax";
import { Formik, Form } from 'formik';
import { m, AnimatePresence } from 'framer-motion';
import * as Yup from 'yup';

// Component
import Header, { HeaderNav, Menu } from "../../Components/Header/Header";
import { Input } from "../../Components/Form/Form";
import Buttons from "../../Components/Button/Buttons";
import axios from "axios";
import InstaQuote2 from "./InstaQuote2";
import { useFormik } from 'formik';
import ClosePopup from "./ClosePopup";

// Data

const InstaQuote = (props) => {
const swiperRef = React.useRef(null);
const navigate = useNavigate() 
const [location, setLocation] = useState({
  location: "",
  storage: "",
  name: "",
  email: "",
  mobilenumber: ""
});





const [fullscreen, setFullscreen] = useState(true);
const [show, setShow] = useState(false);
const [id,setID]=useState(null)
const [showModal, setShowModal] = useState(false);
const handleCloseModal = () => setShowModal(false);
const handleShowModal = () => setShowModal(true);





const handleShow1 = async(breakpoint)=>{
  setFullscreen(breakpoint);

  // Check if location is selected
  if (location.location === "") {
    alert('Please select a location ');
    return;
  }
  setShow(true)

  
  try {
        console.log(location);
       
     await axios.post(`${process.env.REACT_APP_API}/api/member/location`, {location})
    
    .then(res=>{ console.log(res.data.newLoc);
      const id = res.data.newLoc._id; 
      setID(id)
      console.log(id)   
      })
    .catch(err=>{
      console.log(err.message)
    })
   
  } catch (error) {
    console.error(error.response.data); // Handle error response
  } 
  
}
const handleToggleModal = () => setShowModal(prev => !prev);



return (
<div style={props.style}>

  {/* Header Start*/}
  <Header topSpace={{ md: true }} type="reverse-scroll">
    <HeaderNav fluid="fluid" theme="dark" expand="lg"
      className="py-[40px]  px-[35px] md:px-[15px] md:py-[20px] sm:px-0">
      <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
      <Link aria-label="header logo" className="flex items-center" to="/">
      <Navbar.Brand className="inline-block p-0 m-0">
        <img className="default-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-white@2x.webp' alt='logo' />
        <img className="alt-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-green-dark@2x.webp' alt='logo' />
        <img className="mobile-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-black@2x.webp' alt='logo' />
      </Navbar.Brand>
      </Link>
      </Col>

    </HeaderNav>
    <span  className="cursor-pointer float-right pe-[50px] me-[50px] text-dark xs:me-[0px] " onClick={handleToggleModal} style={{ fontSize: '20px' }}>✖</span>
    <Modal show={showModal} onHide={handleCloseModal} centered size="md">
        
        <Modal.Body>
          {/* Your long popup content */}
          {/* You can render the InstaQuote2 component here or any other content */}
         
        </Modal.Body>
        <ClosePopup/>
        {/* You can add a footer if needed */}
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    
    
  </Header>
  {/* Header Start*/}
 

  <Col xl={{ span: 8, offset: 1 }} md={12} className="lg:px-[30px] md:px-[15px] ">
    

 
<div className="m-20 p-20 xs:p-0 xs:mx-2 xs:mt-40   ">
 <h6 className="font-serif font-medium text-darkgray fs-4 ms-3">Where would you like to relocate ?</h6>

 

 
  <div class="form-check p-2 m-3 xs:p-0 xs:m-0 ">
     <input type="radio" class="form-check-input checkbox_label fs-5 m-2"id="hyderabad"  checked ={location === "Hyderabad"}   name="location" value ="Hyderabad"  onChange={(e)=>{setLocation(e.target.value)}} required  />
     <label class="form-check-label text-dark font-medium fs-5 mx-3">Hyderabad</label>
  </div>

   
   <div class="form-check p-2 m-3 xs:p-0 xs:m-0 ">
     <input type="radio" class="form-check-input checkbox_label fs-5 m-2"id="banglore" checked ={location === "Banglore"}   name="location" value ="Banglore"  onChange={(e)=>{setLocation(e.target.value)}} required   />
     <label class="form-check-label text-dark font-medium fs-5 mx-3">Bengaluru</label>
   </div>




   <div class="form-check p-2 m-3 xs:p-0 xs:m-0 ">
     <input type="radio" class="form-check-input checkbox_label fs-5 m-2" id="chennai" checked ={location === "Chennai"}   value ="Chennai" name="location"  onChange={(e)=>{setLocation(e.target.value)}} required   />
     <label class="form-check-label font-medium text-dark fs-5 mx-3">Chennai</label>
   </div>




   <div class="form-button">
    
     <Buttons  onClick={() => handleShow1(true)} className="font-medium font-serif float-right uppercase rounded-none" themeColor={["#502970", "#f34259", "#502970"]} size="lg" color="#fff" title="Continue" />
                            <Modal show={show} fullscreen={fullscreen} >
       
       <Modal.Body  >
         <InstaQuote2 newid={id}/>
       </Modal.Body>
     </Modal>

{/* themeColor={["#0093DD", "#DE0710"]} */}
               
     
   </div>

 </div>

 </Col>





</div>
);
};

export default InstaQuote;