import React, { useEffect, useState } from "react";

// Libraries
import { Col, Container, Modal, Navbar, Row } from "react-bootstrap";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Parallax } from "react-scroll-parallax";
import { Formik, Form } from 'formik';
import { m, AnimatePresence } from 'framer-motion';

// Component
import Header, { HeaderNav, Menu } from "../../Components/Header/Header";
import Buttons from '../../Components/Button/Buttons'
import SocialIcons from "../../Components/SocialIcon/SocialIcons";
import IconWithText from '../../Components/IconWithText/IconWithText'
import InteractiveBanners03 from "../../Components/InteractiveBanners/InteractiveBanners03";
import PortfolioJustifiedGallery from '../../Components/Portfolio/PortfolioJustifiedGallery'
import Clients from '../../Components/Clients/Clients'
import Team from "../../Components/Team/Team";
import Lists from "../../Components/Lists/Lists";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import { TextAnime } from '../../Components/FancyText/FancyText'

import CustomModal from '../../Components/CustomModal'
import { fadeIn, fadeInLeft } from "../../Functions/GlobalAnimations";
import SideButtons from "../../Components/SideButtons";
import InViewPort from "../../Components/InViewPort";
import { zoomIn } from '../../Functions/GlobalAnimations'
import Piechart from '../../Components/PieChart/Piechart';
import Videos from '../../Components/Videos/Videos'
import { Input } from '../../Components/Form/Form'
import { ContactFormStyle02Schema } from '../../Components/Form/FormSchema';
import MessageBox from '../../Components/MessageBox/MessageBox';
import { resetForm, sendEmail } from "../../Functions/Utilities";

// Data
import { InteractiveBannersData03 } from "../../Components/InteractiveBanners/InteractiveBannersData";
import { ProgressBarData02 } from "../../Components/ProgressBar/ProgressBarData";
import { TeamData01 } from "../../Components/Team/TeamData";
import { IconWithTextData_07 } from "../../Components/IconWithText/IconWithTextData";
import { ListData01 } from "../../Components/Lists/ListsData";
import { blogData } from "../../Components/Blogs/BlogData";
import { IconWithTextData_09 } from '../../Components/IconWithText/IconWithTextData';
import { portfolioJustifiedGalleryData } from '../../Components/Portfolio/PortfolioData';
import FooterStyle01 from "../../Components/Footers/FooterStyle01";
import axios from "axios";
import DurationPage from "../InstaQuote/household/DurationPage";



// Libraries






// Component


import InstaQuote2 from "../InstaQuote/InstaQuote2"
import InstaQuote from "../InstaQuote/InstaQuote";
import PopupOnload from "./PopupOnload";
import ContactFormModal from "../ModelPopup/ContactFormModal";
import Register from "../InstaQuote/Register";












// feather-package  fas fa-cubes



const ThankYou = (props) => {
const swiperRef = React.useRef(null);
const params = useParams()
const Thankyouid = props.newID
const [showModal, setShowModal] = useState(true);
const handleCloseModal = () => setShowModal(false);
const handleShowModal = () => setShowModal(true);
const testnew = 0;
const location = useLocation();
/* const data1 = location.state;

const testID = data1.Idp;
console.log('test', data1.Idp) */
/* props.newID = data.Idp; */
console.log(Thankyouid)
const navigate = useNavigate()
const [fullscreen, setFullscreen] = useState(true);
const [show, setShow] = useState(false);
const [showContactPopup, setShowContactPopup] = useState(true);
//console.log('props', props.newId);
// const handleContinue = async (e) => {
//   e.preventDefault();
//   try {
//         console.log(location);
//      await axios.post(`/api/member/location`, {location})
//     .then(res=>{ console.log(res.data.newLoc); 
      
//         navigate(`/instaquote2/${res.data.newLoc._id}`) 
//       })
//     .catch(err=>{
//       console.log(err.message)
//     })
   
//   } catch (error) {
//     console.error(error.response.data); // Handle error response
//   }
// };

const handleShow = async(breakpoint)=>{
 
  setFullscreen(breakpoint);
  setShow(true);

 
}





// useEffect(() => {
//   console.log('useEffect triggered'); 
//   // Hide the contact popup after a certain delay (e.g., 5 seconds)
//   const timer = setTimeout(() => {
//     setShowContactPopup(false);
//   }, 5000); // Adjust the delay as needed

//   // Cleanup function to clear the timer when the component unmounts
//   return () => clearTimeout(timer);
// }, []);

return (
<div style={props.style}>

{/* <Modal show={showContactPopup}  onHide={() => setShowContactPopup(false)}>
        
        <Modal.Body  >
         <PopupOnload/>
        </Modal.Body>
      </Modal> */}

  <SideButtons />
  {/* Header Start*/}
  <Header className="header-with-topbar" topSpace={{ desktop: true }} type="reverse-scroll">
      
        <HeaderNav fluid="fluid" theme="light" bg="white" menu="light" className="px-[35px] py-[0px] md:px-[15px] sm:px-0" containerClass="md:px-0">
          <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
            <Link aria-label="header logo" className="flex items-center" to="/">
              <Navbar.Brand className="inline-block p-0 m-0">
                <img className="default-logo" width="111" height="36"  loading="lazy" src="./assets/img/webp/genx-logo.png" data-rjs="/assets/img/webp/genx-logo.png" alt="logo" />
                <img className="alt-logo" width="111" height="36" loading="lazy" src="./assets/img/webp/genx-logo.png" data-rjs="/assets/img/webp/genx-logo.png" alt="logo" />
                <img className="mobile-logo" width="111" height="36" loading="lazy" src="./assets/img/webp/genx-logo.png" data-rjs="/assets/img/webp/genx-logo.png" alt="logo" />
              </Navbar.Brand>
            </Link>
          </Col>
          <Navbar.Toggle className="order-last md:mx-[15px]">
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          <Navbar.Collapse className="col-auto justify-end p-0">
            <Menu {...props} />
          </Navbar.Collapse>
          {/* <Col className="col-auto text-right !pr-0 pl-[15px] md:pl-0 md:pr-[15px] sm:pr-0">
            <SearchBar className="pl-[17px] xs:px-[15px]" />
            <HeaderLanguage className="pl-[17px] xs:pl-0 xs:pr-0" />
          </Col> */}
           <Col lg={2} xs={"auto"} className="justify-end pe-0 flex items-center">
      
      </Col>
        </HeaderNav>
      </Header>
  {/* Header Start*/}
  {/* Section Start */}

  {/*  <Col lg={2} xs={"auto"} className="justify-end pe-0 flex items-center">
      <SocialIcons theme="social-icon-style-01" size="xs" iconColor="light" data={SocialIconsData} />
      </Col> */}

 
  {/* Section End */}

  
  {/* Section Start */}

  
 {/* Section End */}


{/* Section Start */}

<m.section className="" {...fadeIn}>
  {/* py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] section classname content */}
    <Container>
      <Row className="justify-center">
        <m.div className="col-xl-5 col-lg-6 col-md-8 col-sm-7 mb-20 text-center md:mb-[60px] sm:[44px]" {...fadeIn}>
          <span
            className="mb-[20px] font-medium text-md font-serif uppercase inline-block text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]"></span>
          <h4
            className="font-semibold -tracking-[1px]   text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e] font-serif block heading-5">
            About US</h4>
        </m.div>
      </Row>

      <Row className="items-center justify-center md:items-start sm:text-center">
        <Col lg={4} md={6} className="md:mb-[50px]">
        <m.div className="inline-block text-center w-[300px] py-14 px-[15px] relative" {...{ ...fadeIn, transition: {
          delay: 0.2 } }}>
          <div
            className="border-r-0 border-solid	border-[10px] border-basecolor h-full w-[67px] block absolute bottom-0 left-0">
          </div>
          <h1
            className="text-[80px] leading-[72px] mb-0 mr-[15px] font-semibold tracking-[-5px] text-darkgray font-serif inline-block align-middle">
            03
          </h1>
          <div
            className="w-[40%] leading-[20px] font-medium text-darkgray text-xmd font-serif text-left relative inline-block align-middle lg:w-[50%] sm:w-[35%]">
            Years experience working
          </div>
          <div
            className="border-l-0 border-solid	border-[10px] border-basecolor h-full w-[67px] block absolute bottom-0 right-0">
          </div>
        </m.div>
        </Col>
        <Col lg={3} md={4}>
        <m.div {...{ ...fadeIn, transition: { delay: 0.4 } }}>
          <span className="mb-[20px] text-md font-serif uppercase block">
            Since 2021
          </span>
          <span
            className="w-[85%] leading-[34px] font-medium text-darkgray text-xlg font-serif block lg:leading-0 lg:text-lg sm:w-full sm:mb-[15px]">
          Hyderabad's trusted storage & moving experts
          </span>
        </m.div>
        </Col>
        <Col lg={5} md={10}>
        <m.div {...{ ...fadeIn, transition: { delay: 0.6 } }}>
          <p className="w-[84%] mb-[25px] block lg:w-full text-justify">
          GenX Relocators offers top-tier packing, moving, and self-storage solutions in Hyderabad. Securely store household or office items with our expert care and flexible short- or long-term options. With a 5-star Google Reviews rating from 160+ satisfied clients, trust us to handle your belongings           </p>
          <Buttons onClick={() => handleShow(true)} className="font-medium font-serif xs:m-0 uppercase rounded-none"
    themeColor={["#502970", "#f34259", "#502970"]} size="sm" color="#fff" title="Continue" />

       <Modal show={show} fullscreen={fullscreen} >
   
   <Modal.Body  >
    <DurationPage newID = {props.newId}/>;
   </Modal.Body>
   </Modal>
        
          
          <Buttons   onClick={() => handleShow(true)}
            className="  font-medium font-serif uppercase btn-link  text-md after:bg-black after:xs-[br] float-right xs:float-none   after:h-[1px] after:!-bottom-[5px] hover:text-darkgray md:text-md md:mb-[15px]"
            color="#232323" title="Get Insta Quote" size="xl" /> 
            
            
        </m.div>

        </Col>
     
      </Row>
    </Container>
  </m.section>

  
 


  
 
</div>
);
};

export default ThankYou;