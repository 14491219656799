import React, { useEffect, useState } from "react";

// Libraries
import { Button, Card, Col, Container, Modal, Navbar, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Parallax } from "react-scroll-parallax";
import { Formik, Form } from 'formik';
import { m, AnimatePresence } from 'framer-motion';
import * as Yup from 'yup';

// Component
import Header, { HeaderNav, Menu } from "../../../Components/Header/Header";
import { Input } from "../../../Components/Form/Form";
import Buttons from "../../../Components/Button/Buttons";
import axios from "axios";
import { fadeIn } from "../../../Functions/GlobalAnimations";
import MoveInPage from "./MoveInPage";
import ClosePopup from "../ClosePopup";



// Data








const PickupPage = (props) => {
const swiperRef = React.useRef(null);
const navigate = useNavigate() 

const [quoteFullscreen, setQuoteFullscreen] = useState(true);
  const [quoteShow, setQuoteShow] = useState(false);
  const [pickup,setPickup]=useState({})
  const [showModal, setShowModal] = useState(false);
const handleCloseModal = () => setShowModal(false);
const handleShowModal = () => setShowModal(true);

  const handleShow1 = async(breakpoint)=>{
    setQuoteFullscreen(breakpoint);
    setQuoteShow(true)
  }

  const handleReview = async()=>{
    try {
    await axios.get(`${process.env.REACT_APP_API}/api/member/pickup/${props.newid}`)
    .then((res)=>{
     console.log(res.data)
    setPickup(res.data.extId)
    
    
    
    })
    .catch((err)=>console.log(err.message))
    
    } catch (error) {
    console.log(error.message)
    }
    }

    useEffect(()=>{
      
      handleReview();
      
      },[])


      const handleToggleModal = () => setShowModal(prev => !prev);



return (
<div style={props.style}>

  {/* Header Start*/}
  <Header topSpace={{ md: true }} type="reverse-scroll">
    <HeaderNav fluid="fluid" theme="dark" expand="lg"
      className="py-[40px]  px-[35px] md:px-[15px] md:py-[20px] sm:px-0">
      <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
      <Link aria-label="header logo" className="flex items-center" to="/">
      <Navbar.Brand className="inline-block p-0 m-0">
        <img className="default-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-white@2x.webp' alt='logo' />
        <img className="alt-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-green-dark@2x.webp' alt='logo' />
        <img className="mobile-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-black@2x.webp' alt='logo' />
      </Navbar.Brand>
      </Link>
      </Col>

    </HeaderNav>
    
        {/* You can add a footer if needed */}
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer> */}
   
  </Header>
  {/* Header Start*/}

  <m.section className="py-[70px] lg:py-[90px] sm:py-[75px] xs:py-[100px]" {...fadeIn}>
    <Container>
      <Row>
        <Col xl={{ span: 8, offset: 2 }} md={12} className=''>
        

        <div className="bg-lightgray p-[50px] lg:py-[30px] lg:px-[20px] md:py-5">
        

          <div className="row" >

            <h1 className="font-serif  text-lg text-success mt-5 mb-0  inline-block fs-1 font-medium">Pick-Up Confirmed</h1>

            <div className="col-md-6">
            <h1 className="font-serif  text-center text-lg  mt-5 mb-0  inline-block fs-5 font-medium">Pick-up date:</h1>
            <p className="fs-5 text-success "> <span>{pickup.date}</span></p>
            <p className="fs-6 text-success "> <span>{pickup.time}</span></p>
            
            </div>
            <div className="col-md-6">
            <h1 className="font-serif text-center text-lg  mt-5 mb-0 inline-block fs-5 font-medium">Pick-Up Address: </h1>
            <p className="fs-5 text-success ">{pickup.address}</p>
            <p className="fs-5 text-success ">{pickup.floor} floor,{pickup.city}</p>
            
            <p className="fs-5 text-success">{pickup.city}-{pickup.pin}</p>
            </div>

            <hr />
            <p>
            We have sent confirmation email for reference.
              
            </p>
            <p> Kindly switch off the Refrigerator & Washing Machine before 24hrs of the pick-up time and keep the door open to ensure moisture free by the pickup time</p>
            <p>Strictly edibles/liquids are not allowed for the storage</p>
            <br />
            <p>We have assigned dedicated relationship manager Amit, +919866012094 to assist you with pickup and storage.</p>
            <br />
            <p>If you have any questions regarding pickup, send email to info@genxrelocators.com or reach out to your relationship manager or support team at +919866012094 for help.</p>
            <p>Looking forward to serving you.</p>
           
            <p>Best Regards,</p>
            <p>The GenX Team</p>
          
            
          </div>
          {/* <m.section className="pt-[60px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] xs:px-0" {...fadeIn}>
       
        <Container>
          <Row className="justify-center">
            <Col className="text-center">
              <Buttons onClick={(e)=>{ e.preventDefault();  handleShow1(true)}} ariaLabel="button" href="#" className="mx-[10px] font-medium font-serif rounded-none uppercase btn-flat" size="xl" themeColor="#232323" color="#fff" title="Book A Slot" />
              <Modal show={quoteShow} fullscreen={quoteFullscreen} >
        
        <Modal.Body  ><span aria-hidden="true" id="close_span" class="float-right fs-1 fw-1 p-5 m-5">×</span>
      
      <MoveInPage/>
        </Modal.Body>
      </Modal>
            </Col>
          </Row>
        </Container>
      </m.section> */}
        </div>
        </Col>
      </Row>
    </Container>
  </m.section>

  





</div>
);
};

export default PickupPage;