import React, { useEffect } from 'react'


// Libraries
import { Link } from 'react-router-dom';
import { Col, Container, Modal, Navbar, Row } from 'react-bootstrap';
import { m } from "framer-motion";




import Header, { HeaderLanguage, HeaderNav, Menu, SearchBar, Topbar } from '../../Components/Header/Header';
import { fadeIn } from '../../Functions/GlobalAnimations';


function GoogleReviews(props) {

    // useEffect(() => {
    //     // Create a script element
    //     const script = document.createElement('script');
        
    //     // Set the src attribute to the script URL
    //     script.src = "https://widget.trustmary.com/0of8FIQ6-";
    
    //     // Append the script to the body
    //     document.body.appendChild(script);
    
    //     // Clean up function to remove the script when the component unmounts
    //     return () => {
    //       document.body.removeChild(script);
    //     };
    //   }, []);
  return (
    <div>
        {/* Header Start */}
      <Header className="header-with-topbar" topSpace={{ desktop: true }} type="reverse-scroll">
        {/* <Topbar className="bg-lightgray border-b home-business-topbar border-[#0000001a] sm:hidden md:px-0 header-with-topbar border-bottom px-[35px]">
          <Container fluid>
            <Row className="justify-between pl-[15px] lg:pr-[15px] items-center">
              <Col className="col-auto none sm:block text-end lg:px-0">
                <span className="top-bar-contact-list border-l border-inherit	py-[9px] px-[18px] text-[13px] inline-block float-left">
                  <i className="feather-phone-call text-darkgray mr-[6px] text-md relative top-[1px]"></i>  0222 8899900
                </span>
                <span className="border-l border-inherit py-[9px] pl-[18px] text-[13px] inline-block float-left border-r-0 pr-0">
                  <i className="feather-map-pin text-darkgray mr-[6px] text-md relative top-[1px]"></i> 401 Broadway, 24th Floor, San Francisco
                </span>
              </Col>
            </Row>
          </Container>
        </Topbar> */}
        <HeaderNav fluid="fluid" theme="light" bg="white" menu="light" className="px-[35px] py-[0px] md:px-[15px] sm:px-0" containerClass="md:px-0">
          <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
            <Link aria-label="header logo" className="flex items-center" to="/">
              <Navbar.Brand className="inline-block p-0 m-0">
                <img className="default-logo" width="111" height="36" loading="lazy" src="./assets/img/webp/genx-logo.png" data-rjs="/assets/img/webp/logo-gradient-sky-blue-pink@2x.webp" alt="logo" />
                <img className="alt-logo" width="111" height="36" loading="lazy" src="./assets/img/webp/genx-logo.png" data-rjs="/assets/img/webp/logo-black@2x.webp" alt="logo" />
                <img className="mobile-logo" width="111" height="36" loading="lazy" src="./assets/img/webp/genx-logo.png" data-rjs="/assets/img/webp/logo-gradient-sky-blue-pink@2x.webp" alt="logo" />
              </Navbar.Brand>
            </Link>
          </Col>
          <Navbar.Toggle className="order-last md:mx-[15px]">
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          <Navbar.Collapse className="col-auto justify-end p-0">
            <Menu {...props} />
          </Navbar.Collapse>
          {/* <Col className="col-auto text-right !pr-0 pl-[15px] md:pl-0 md:pr-[15px] sm:pr-0">
            <SearchBar className="pl-[17px] xs:px-[15px]" />
            <HeaderLanguage className="pl-[17px] xs:pl-0 xs:pr-0" />
          </Col> */}
        </HeaderNav>
      </Header>
      <Container>
            <Row className="justify-center mt-5">
              <m.div className="col-xl-6 col-lg-7 col-sm-8 mb-20 text-center w-[51%] xl:mb-[70px] lg:mb-[65px] md:mb-[60px] sm:mb-[55px] md:w-[68%] xs:w-full" {...fadeIn}>
                <span className="inline-block font-serif text-md uppercase mb-[20px] font-medium  text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e] xs:mb-[15px]"> Live Customer Reviews on Google</span>
                <h5 className="w-full mb-[20px] font-semibold text-darkgray font-serif -tracking-[1px]"> Google Real-Time Raves </h5>
              </m.div>
            </Row>
          </Container>
      {/* Header End */}
      <div class="elfsight-app-d48a08b6-c2b2-4671-885f-f079ab27d861" data-elfsight-app-lazy></div>
     
    </div>
  )
}

export default GoogleReviews
