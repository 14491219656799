import React, { memo } from 'react'

// Libraries
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { PropTypes } from "prop-types";

// Components
import SocialIcons from '../SocialIcon/SocialIcons';
import FooterMenu, { Footer } from './Footer';
import StaticInstagram from '../Instagram/StaticInstagram';

// Data
import FooterData from './FooterData';

const iconData = 
[
    
    {
        color: "#828282",
        link: "https://in.linkedin.com/company/genx-relocators?trk=public_post_feed-actor-name",
        icon: "fab fa-linkedin"
    },
    {
        color: "#828282",
        link: "https://www.facebook.com/genxrelocators",
        icon: "fab fa-facebook-f"
    },
    {
        color: "#828282",
        link: "https://twitter.com/GenxRelocators",
        icon: "fab fa-twitter"
    },
    {
        color: "#828282",
        link: "https://www.instagram.com/p/C32hAdCuKG5",
        icon: "fab fa-instagram"
    },
]

const FooterStyle01 = (props) => {
    return (
        <Footer theme={props.theme} className={`${props.className ? ` ${props.className}` : ""}`}>
            
            <div className="py-[5%] lg:py-[95px] md:py-[50px]">
                <Container>
                    <Row>
                    <Col lg={{ span: 3, order: 0 }} md={6} sm={{ span: 6, order: 1 }} className="md:mb-[50px] xs:mb-[25px]">
                            <Link aria-label="link" to="/" className="mb-[20px] block">
                                <img alt="logo" src={props.logo} width="111" height="36" />
                            </Link>
                            <p className="w-[80%] mb-[20px] xs:w-full">GenX have team of expertise staff for handling Packing & Transportation activities on day to day basis. We work closely with our channel partners.</p>
                            <SocialIcons theme="social-icon-style-01" className="justify-start" size="xs" iconColor={props.theme === "dark" ? "light" : "dark"} data={iconData} />
                        </Col>
                        <FooterMenu data={FooterData.slice(0, 2)} lg={3} sm={6} className="xl:px-[15px] md:mb-[40px] xs:mb-[25px]" titleClass="capitalize" />
                        <Col lg={3} sm={6} className="xs:mb-[25px]">
                            <span className="mb-[20px] font-serif block font-medium text-themecolor xs:mb-[10px]">CONTACT US</span>
                            <p className="w-[85%] mb-[15px]"># 1-25-181/3 ,Plot No.121 & 122 , Brundavan Nagar ,Gollaguda Trimulgherry -Secunderabad-Telangana -500015</p>
                            <div><i className="feather-phone-call text-sm mr-[10px] text-themecolor"></i>+91 9866012094 <br></br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+91 9866006290</div>
                            <div><i className="feather-mail text-sm mr-[10px] text-themecolor"></i><a aria-label="mail" href="mailTo:info@yourdomain.com">info@genxrelocators.com</a></div>
                        </Col>
                        {/* <Col lg={3} sm={6}>
                            <span className="mb-[20px] font-medium font-serif text-themecolor block xs:mb-[20px]">Follow us on Instagram</span>
                            <div className="w-full inline-block">
                                <StaticInstagram total_posts={3} />
                                <a aria-label="link" rel="noreferrer" href="https://www.instagram.com/" target="_blank" className="flex justify-start text-slateblue font-medium text-xs font-serif uppercase mt-[20px] items-center hover:text-white">
                                    <i className="fab fa-instagram text-lg mr-[10px] text-gradient bg-gradient-to-r from-[#ff85a6] to-[#b783ff]"></i>
                                    <span className="inline-block hover:text-white">Follow instagram</span>
                                </a>
                            </div>
                        </Col> */}
                    </Row>
                </Container>
            </div>
            <div className="py-[40px] border-t border-[#ffffff1a]">
                <Container>
                    <Row>
                        <Col md={3} className="sm:mb-[20px]">
                            {/* <Link aria-label="homepage" to="/" className="sm:flex sm:justify-center">
                                <img alt="logo" src={props.logo} width="111" height="36" />
                            </Link> */}
                        </Col>
                        <Col md={6} className="flex justify-center items-center text-center sm:mb-[20px]">
                            <p className="mb-0">&copy; {new Date().getFullYear()}  Powered by <a aria-label="themezaa" rel="noreferrer" href="https://erinome.com/" className="hover:text-white" target="_blank"> Erinome</a></p>
                        </Col>
                        <Col md={3} className="text-right sm:text-center">
                            {/* <SocialIcons size="xs" theme="social-icon-style-01" className="justify-end sm:justify-center" iconColor={props.theme === "dark" ? "light" : "dark"} data={iconData} /> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </Footer>
    )
}

FooterStyle01.defaultProps = {
    data: FooterData,
    className: "bg-darkgray text-[#828282]",
    logo: "./assets/img/webp/genx-logo-white.png",
    theme: "light"
}

FooterStyle01.propTypes = {
    className: PropTypes.string,
    logo: PropTypes.string,
}

export default memo(FooterStyle01)
