const InteractiveBannersData01 = [
    {
        subtitle: "Branding",
        title: "Leading branding agency based in washington",
        btnLink: "/blogs/blog-post-layout-01",
        img: "https://via.placeholder.com/798x577",
        icon: "line-icon-Arrow-OutRight"
    },
    {
        subtitle: "Designing",
        title: "Creative design agency working for huge brands",
        btnLink: "/blogs/blog-post-layout-02",
        img: "https://via.placeholder.com/798x577",
        icon: "line-icon-Arrow-OutRight"
    },
    {
        subtitle: "Development",
        title: "Development is the process of build websites",
        btnLink: "/blogs/blog-post-layout-03",
        img: "https://via.placeholder.com/798x577",
        icon: "line-icon-Arrow-OutRight"
    },
    {
        subtitle: "Marketing",
        title: "Reach your ideal customers on the largest network",
        btnLink: "/blogs/blog-post-layout-04",
        img: "https://via.placeholder.com/798x577",
        icon: "line-icon-Arrow-OutRight"
    }
]

const InteractiveBannersData02 = [
    {
        subtitle: "Our personal storage, are the perfect place to keep your seldom used items",
        title: "Have more Stuff than your space? ",
        img: "./assets/img/webp/more-stuff.png",
        btnTitle: "Get Insta Quote",
        
    },
    {
        subtitle: "We have room for all of your unique needs",
        title: "Renovating or relocating",
        img: "./assets/img/webp/renovating-relocating.png",
        btnTitle: "Get Insta Quote",
     
    },
    {
        subtitle: "We offer short term on demand storage for your possessions.",
        title: "Short term business trips",
        img: "./assets/img/webp/short-term-busness-trip.png",
        btnTitle: "Get Insta Quote",
        
    },
    {
        subtitle: "Provide storage solutions for students returning home.",
        title: "Students travelling back home for vacation",
        img: "./assets/img/webp/students-vacation-storage.png",
        btnTitle: "Get Insta Quote",
       
    },
]

const InteractiveBannersData03 = [
    {
        title: "Renovating or Relocating",
        subtitle: "Move Assistance",
        img: "./assets/img/webp/renovating.png",
        imgLink: "/page/pricing-packages",
        btnTitle: "Get Instant Quote",
        titleLink: "#",
        icon: "fas fa-arrow-right"
    },
    {
        title: "Short-Term Corporate Stints",
        subtitle: "Secure Storage Facility",
        img: "./assets/img/webp/short-term.png",
        imgLink: "/page/pricing-packages",
        btnTitle: "Get Instant Quote",
        titleLink: "#",
       
        icon: "fas fa-arrow-right"
    },
    {
        title: "Business or Corporate Storage",
        subtitle: "You need it, We have it",
        img: "./assets/img/webp/business.png",
        imgLink: "/page/pricing-packages",
        btnTitle: "Get Instant Quote",
        titleLink: "#",
       
        icon: "fas fa-arrow-right"
    },
    {
        title: "Data Server Migration Service",
        subtitle: "Server relocation",
        img: "./assets/img/webp/data-server.png",
        imgLink: "/page/pricing-packages",
        btnTitle: "Get Instant Quote",
        titleLink: "#",
        icon: "fas fa-arrow-right"
    }
]

const InteractiveBannersData04 = [
    {
        title: 'Website design',
        content: "Lorem ipsum dolor consectetur adipiscing elit do eiusmod tempor incididunt ut labore dolore",
        img: "https://via.placeholder.com/464x610",
        btnTitle: "Explore services",
        btnLink: "/page/our-services",
        icon: "line-icon-Add-Window"
    },
    {
        title: "eCommerce experience",
        content: "Lorem ipsum dolor consectetur adipiscing elit do eiusmod tempor incididunt ut labore dolore",
        img: "https://via.placeholder.com/464x610",
        btnTitle: "Explore services",
        btnLink: "/page/our-services",
        icon: "line-icon-Add-Bag"
    },
    {
        title: "Digital platform",
        content: "Lorem ipsum dolor consectetur adipiscing elit do eiusmod tempor incididunt ut labore dolore",
        img: "https://via.placeholder.com/464x610",
        btnTitle: "Explore services",
        btnLink: "/page/our-services",
        icon: "line-icon-Android-Store"
    },
    {
        title: "Interface design",
        content: "Lorem ipsum dolor consectetur adipiscing elit do eiusmod tempor incididunt ut labore dolore",
        img: "https://via.placeholder.com/464x610",
        btnTitle: "Explore services",
        btnLink: "/page/our-services",
        icon: "line-icon-Cursor-Click2"
    }
]

const InteractiveBannersData05 = [
    {
        title: "Senior web designer",
        subtitle: "2 position open",
        img: "https://via.placeholder.com/800x610",
        btnTitle: "Apply now",
        btnLink: "/page/contact-simple",
        icon: "ti-user"
    },
    {
        title: "SENIOR DEVELOPER",
        subtitle: "3 position open",
        img: "https://via.placeholder.com/800x610",
        btnTitle: "Apply now",
        btnLink: "/page/contact-simple",
        icon: "ti-user"
    },
    {
        title: "UX design developer",
        subtitle: "4 position open",
        img: "https://via.placeholder.com/800x610",
        btnTitle: "Apply now",
        btnLink: "/page/contact-simple",
        icon: "ti-user"
    }
]

const InteractiveBannersData06 = [
    {
        title: "wedding",
        img: "https://via.placeholder.com/600x816",
        btnTitle: "Portfolio",
        btnLink: "/portfolio/portfolio-justified-gallery",
        imgLink: "#"
    },
    {
        title: "product",
        img: "https://via.placeholder.com/600x816",
        btnTitle: "Portfolio",
        btnLink: "/portfolio/portfolio-justified-gallery",
        imgLink: "#"
    },
    {
        title: "outdoor",
        img: "https://via.placeholder.com/600x816",
        btnTitle: "Portfolio",
        btnLink: "/portfolio/portfolio-justified-gallery",
        imgLink: "#"
    }
]

const InteractiveBannersData07 = [
    {
        country: "India",
        img: "https://via.placeholder.com/525x525",
        btnTitle: "Explore tours",
        btnLink: "#",
        imgLink: "#"
    },
    {
        country: "London",
        img: "https://via.placeholder.com/525x525",
        btnTitle: "Explore tours",
        btnLink: "#",
        imgLink: "#"
    },
    {
        country: "Australia",
        img: "https://via.placeholder.com/525x525",
        btnTitle: "Explore tours",
        btnLink: "#",
        imgLink: "#"
    },
    {
        country: "Portugal",
        img: "https://via.placeholder.com/525x525",
        btnTitle: "Explore tours",
        btnLink: "#",
        imgLink: "#"
    },
    {
        country: "Africa",
        img: "https://via.placeholder.com/525x525",
        btnTitle: "Explore tours",
        btnLink: "#",
        imgLink: "#"
    },
    {
        country: "Italy",
        img: "https://via.placeholder.com/525x525",
        btnTitle: "Explore tours",
        btnLink: "#",
        imgLink: "#"
    },
    {
        country: "Nepal",
        img: "https://via.placeholder.com/525x525",
        btnTitle: "Explore tours",
        btnLink: "#",
        imgLink: "#"
    },
    {
        country: "Indonesia",
        img: "https://via.placeholder.com/525x525",
        btnTitle: "Explore tours",
        btnLink: "#",
        imgLink: "#"
    }
]

const InteractiveBannersData08 = [
    {
        img: "https://via.placeholder.com/868x674",
        title: "The trescoat spa resort",
        country: "Africa",
        btnLink: "#",
        imgLink: "#"
    },
    {
        img: "https://via.placeholder.com/868x674",
        country: "Thailand",
        title: "Moongo spa resort",
        btnLink: "#",
        imgLink: "#"
    },
    {
        img: "https://via.placeholder.com/868x674",
        title: "Grand canyon baloon",
        country: "Singapore",
        btnLink: "#",
        imgLink: "#"
    }
]

const InteractiveBannersData09 = [
    {
        img: "https://via.placeholder.com/266x266",
        title: "Discover forest",
        subtitle: "+990 Destinations",
        btnTitle: "Explore all tour",
        btnLink: "#"
    },
    {
        img: "https://via.placeholder.com/266x266",
        title: "Mountain madness",
        subtitle: "+650 Destinations",
        btnTitle: "Explore all tour",
        btnLink: "#"
    },
    {
        img: "https://via.placeholder.com/266x266",
        title: "Unexplored beaches",
        subtitle: "+250 Destinations",
        btnTitle: "Explore all tour",
        btnLink: "#"
    },
    {
        img: "https://via.placeholder.com/266x266",
        title: "Romantic getaways",
        subtitle: "+350 Destinations",
        btnTitle: "Explore all tour",
        btnLink: "#"
    }
]

const InteractiveBannersData10 = [
    {
        img: "https://via.placeholder.com/800x1105",
        title: "RUNNING HAPPINESS",
        subtitle: "CREATED TO SUPPORT",
        btnTitle: "Explore more",
        btnLink: "#"
    },
    {
        img: "https://via.placeholder.com/800x1105",
        title: "FITNESS JOURNEY",
        subtitle: "FEMINIST OVER POWER",
        btnTitle: "Explore more",
        btnLink: "#"
    },
    {
        img: "https://via.placeholder.com/800x1105",
        title: "MAINTAIN FITNESS",
        subtitle: "SPORT HAPPINESS FANTASY",
        btnTitle: "Explore more",
        btnLink: "#"
    },
    {
        img: "https://via.placeholder.com/800x1105",
        title: "POSSIBLE EXERCISE",
        subtitle: "POWER OVERWHELMING",
        btnTitle: "Explore more",
        btnLink: "#"
    }
]

const InteractiveBannersData12 = [
    {
        title: "Design solutions",
        content: "Lorem ipsum dolor sit consectetur adipiscing sed eiusmod tempor.",
        img: "https://via.placeholder.com/700x972",
        btnTitle: "View more",
        btnLink: "#",
        icon: "fas fa-angle-right"
    },
    {
        title: "Walls painting",
        content: "Lorem ipsum dolor sit consectetur adipiscing sed eiusmod tempor.",
        img: "https://via.placeholder.com/700x972",
        btnTitle: "View more",
        btnLink: "#",
        icon: "fas fa-angle-right"
    },
    {
        title: "Interior decoration",
        content: "Lorem ipsum dolor sit consectetur adipiscing sed eiusmod tempor.",
        img: "https://via.placeholder.com/700x972",
        btnTitle: "View more",
        btnLink: "#",
        icon: "fas fa-angle-right"
    },
]

const InteractiveBannersData13 = [
    {
        title: "Scott armchair",
        img: "https://via.placeholder.com/200x193",
        icon: "line-icon-Arrow-OutRight"
    },
    {
        title: "Portable table",
        img: "https://via.placeholder.com/200x193",
        icon: "line-icon-Arrow-OutRight"
    },
    {
        title: "Seating stool",
        img: "https://via.placeholder.com/200x193",
        icon: "line-icon-Arrow-OutRight"
    },
    {
        title: "Rocking chair",
        img: "https://via.placeholder.com/200x193",
        icon: "line-icon-Arrow-OutRight"
    },
]

const InteractiveBannersData14 = [
    {
        img: "https://via.placeholder.com/600x660",
        title: "Honeymoon package",
        subtitle: "GET 50% OFF",
        content: "Lorem ipsum dolor amet adipiscing tempor",
        btnTitle: "Book Now",
        btnLink: "#"
    },
    {
        img: "https://via.placeholder.com/600x660",
        title: "Cocktail package",
        subtitle: "GET 30% OFF",
        content: "Lorem ipsum dolor amet adipiscing tempor",
        btnTitle: "Book Now",
        btnLink: "#"
    },
    {
        img: "https://via.placeholder.com/600x660",
        title: "Massage package",
        subtitle: "GET 50% OFF",
        content: "Lorem ipsum dolor amet adipiscing tempor",
        btnTitle: "Book Now",
        btnLink: "#"
    }
]

const InteractiveBannersData15 = [
    {
        img: "https://via.placeholder.com/800x1113",
        title: "Architecture design",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting.",
        btnTitle: "See Portfolio",
        btnLink: "/portfolio/portfolio-boxed-metro"
    },
    {
        img: "https://via.placeholder.com/800x1113",
        title: "Structure design",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting.",
        btnTitle: "See Portfolio",
        btnLink: "/portfolio/portfolio-boxed-metro"
    },
    {
        img: "https://via.placeholder.com/800x1113",
        title: "Furnitures design",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting.",
        btnTitle: "See Portfolio",
        btnLink: "/portfolio/portfolio-boxed-metro"
    },
    {
        img: "https://via.placeholder.com/800x1113",
        title: "Landscape design",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting.",
        btnTitle: "See Portfolio",
        btnLink: "/portfolio/portfolio-boxed-metro"
    },
]

export { InteractiveBannersData01, InteractiveBannersData02, InteractiveBannersData03, InteractiveBannersData04, InteractiveBannersData05, InteractiveBannersData06, InteractiveBannersData07, InteractiveBannersData08, InteractiveBannersData09, InteractiveBannersData10, InteractiveBannersData12, InteractiveBannersData13, InteractiveBannersData14, InteractiveBannersData15 }