import React, { useState } from "react";

// Libraries
import { Button, Card, Col, Container, Modal, Navbar, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Parallax } from "react-scroll-parallax";
import { Formik, Form } from 'formik';
import { m, AnimatePresence } from 'framer-motion';
import * as Yup from 'yup';

// Component
import Header, { HeaderNav } from "../../../Components/Header/Header";

import Buttons from "../../../Components/Button/Buttons";
import axios from "axios";
import HouseholdPage from "../household/HouseholdPage"
import Duration from "./DurationPage";
import ClosePopup from "../ClosePopup";



// Data








const Move = (props) => {

 // console.log('move1 id', props.newID);
const swiperRef = React.useRef(null);
const navigate = useNavigate() 

const [move,setMove]=useState("")
const [fullscreen, setFullscreen] = useState(true);
const [show, setShow] = useState(false);
const [mFullscreen, setMFullscreen] = useState(true);
const [mShow, setMShow] = useState(false);
const [showModal, setShowModal] = useState(false);
const handleCloseModal = () => setShowModal(false);
const handleShowModal = () => setShowModal(true);
const handleToggleModal = () => setShowModal(prev => !prev);

const handleShow = async(breakpoint)=>{
  setFullscreen(breakpoint);
  setShow(true);

  try {
    /* console.log(move);
    
    console.log('move2 id',props.newID); */
    await axios.patch(`${process.env.REACT_APP_API}/api/member/move/${props.newID}`, {move})
    .then(res=>{
       console.log(res.data);
    })
    .catch(err=>{
    console.log(err.message)
    })
    } catch (error) {
    console.log(error.message)
    }
 
  }

  const handleShow2 = async(breakpoint)=>{
 

    setMFullscreen(breakpoint);
    setMShow(true);
  
    }






return (
<div style={props.style}>

  {/* Header Start*/}
  <Header topSpace={{ md: true }} type="reverse-scroll">
    <HeaderNav fluid="fluid" theme="dark" expand="lg"
      className="py-[40px]  px-[35px] md:px-[15px] md:py-[20px] sm:px-0">
      <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
      <Link aria-label="header logo" className="flex items-center" to="/">
      <Navbar.Brand className="inline-block p-0 m-0">
        <img className="default-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-white@2x.webp' alt='logo' />
        <img className="alt-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-green-dark@2x.webp' alt='logo' />
        <img className="mobile-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-black@2x.webp' alt='logo' />
      </Navbar.Brand>
      </Link>
      </Col>

    </HeaderNav>
    <span onClick={handleToggleModal} className="cursor-pointer float-right pe-[50px] me-[50px] text-dark xs:me-[0px] " style={{ fontSize: '20px' }}>✖</span>
    <Modal show={showModal} onHide={handleCloseModal} centered size="md">
        
        <Modal.Body>
          {/* Your long popup content */}
          {/* You can render the InstaQuote2 component here or any other content */}
         
        </Modal.Body>
        <ClosePopup/>
        {/* You can add a footer if needed */}
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
  </Header>
  {/* Header Start*/}


  <Col xl={{ span: 8, offset: 1 }} md={12} className="lg:px-[30px] md:px-[15px] ">

 
<div className="m-20 p-20 xs:p-5 xs:m-5 xs:p-0 xs:mx-2 xs:mt-40   ">
 <h6 className="font-serif font-medium text-darkgray fs-4 ms-3">When do you want to relocate ?</h6>

 <div class="form-check p-2 m-3 xs:p-0 xs:m-0 ">
     <input type="radio" class="form-check-input checkbox_label fs-5 m-2"id="Immediate"  checked={move==="Immediate"}     name="move" value ="Immediate" onChange={(e)=>{setMove(e.target.value)}} />
     <label class="form-check-label text-dark font-medium fs-5 mx-3">Immediately</label>
   </div>

   
   <div class="form-check p-2 m-3 xs:p-0 xs:m-0 ">
     <input type="radio" class="form-check-input checkbox_label fs-5 m-2"id="Next Week"  checked={move==="Next Week"}     name="move" value ="Next Week" onChange={(e)=>{setMove(e.target.value)}} />
     <label class="form-check-label text-dark font-medium fs-5 mx-3">Next Week</label>
   </div>


   <div class="form-check p-2 m-3 xs:p-0 xs:m-0 ">
     <input type="radio" class="form-check-input checkbox_label fs-5 m-2"id="I'm flexible" checked={move==="I'm flexible"}   name="move" value ="I'm flexible" onChange={(e)=>{setMove(e.target.value)}} />
     <label class="form-check-label text-dark font-medium fs-5 mx-3">I'm flexible</label>
   </div>


   <div class="form-check p-2 m-3 xs:p-0 xs:m-0 " >
     <input type="radio" class="form-check-input checkbox_label fs-5 m-2" id="Within a month" checked={move==="Within a month"}    value ="Within a month" name="move" onChange={(e)=>{setMove(e.target.value)}} />
     <label class="form-check-label font-medium text-dark fs-5 mx-3">Within a month</label>
   </div>

<div className="btn-group d-flex justify-content-between">

<div className="form-button">
            <Buttons onClick={(e)=>{handleShow2(true)}}
              className="font-medium font-serif xs:m-0 uppercase rounded-none"
              themeColor={["#502970", "#f34259", "#502970"]}
              size="lg"
              color="#fff"
              title="Previous"
            />
            <Modal show={mShow} fullscreen={mFullscreen} >
        
        <Modal.Body  >{/* <span aria-hidden="true" id="close_span" class="float-right fs-1 fw-1 p-5 m-5">×</span> */}
      
       <Duration/>
        </Modal.Body>
      </Modal>
          </div>
  
<div class="form-button">
    
    <Buttons onClick={(e)=>{handleShow(true)}} className="font-medium font-serif xs:m-0 uppercase rounded-none" themeColor={["#502970", "#f34259", "#502970"]} size="lg" color="#fff" title="Continue" />

<Modal show={show} fullscreen={fullscreen} >
       
       <Modal.Body  >{/* <span aria-hidden="true" id="close_span" class="float-right fs-1 fw-1 p-5 m-5">×</span> */}
       <HouseholdPage  newID={props.newID}/>
       </Modal.Body>
     </Modal>
</div>

  
</div>

  

 </div>

 </Col>

  





</div>
);
};

export default Move;