import React, { useEffect, useState } from "react";

// Libraries
import { Button, Card, Col, Container, Modal, Navbar, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Parallax } from "react-scroll-parallax";
import { Formik, Form, FormikConsumer } from 'formik';
import { m, AnimatePresence } from 'framer-motion';
import * as Yup from 'yup';

// Component
import Header, { HeaderNav, Menu } from "../../../Components/Header/Header";
import { Input } from "../../../Components/Form/Form";
import Buttons from "../../../Components/Button/Buttons";
import axios from "axios";
import StorageRentalPayment from "./StorageRentalPayment";
import ClosePopup from "../ClosePopup";



// Data



const AddressPage = (props) => {
const swiperRef = React.useRef(null);
const params = useParams()
const storageType = props.newStorage

const navigate = useNavigate()

const [user,setUser]=useState({
address:"",
floor:"",
city:"",
pin:"",
serviceLift:""
})

const [fullscreen, setFullscreen] = useState(true);
const [show, setShow] = useState(false);
const [showModal, setShowModal] = useState(false);
const handleCloseModal = () => setShowModal(false);
const handleShowModal = () => setShowModal(true);

const readValue = async(e)=>{
const {name,value} = e.target
setUser({...user,[name]:value})
}





const handleShow = async(breakpoint)=>{

  try {
    console.log(props.newid)
    console.log("address",user)
    if (user.pin.length !== 6) {
  
      alert('Enter 6 digit pincode');
      return;
    }

    await axios.patch(`${process.env.REACT_APP_API}/api/member/address/${props.newid}`, user)
      .then(res=>{
         console.log(res.data.addr);
      })
      .catch(err=>{
      console.log(err.message)
      })
    
  } catch (error) {
    console.log(error.message)
  }
  // try {
  //   console.log(props.newid)
  //   console.log(user);
    
  //   await axios.patch(`/api/member/address/${props.newid}`, user)
  //   .then(res=>{
  //      console.log(res.data.addr);
  //   })
  //   .catch(err=>{
  //   console.log(err.message)
  //   })
  //   } catch (error) {
  //   console.log(error.message)
  //   }


setFullscreen(breakpoint);
setShow(true);
}
const handleInputChange = (e) => {
  const { name, value, type, checked } = e.target;
  setUser(prevState => ({
    ...prevState,
    [name]: type === 'checkbox' ? checked : value
  }));
};



const handleToggleModal = () => setShowModal(prev => !prev);




return (
<div style={props.style}>

  {/* Header Start*/}
  <Header topSpace={{ md: true }} type="reverse-scroll">
    <HeaderNav fluid="fluid" theme="dark" expand="lg"
      className="py-[40px]  px-[35px] md:px-[15px] md:py-[20px] sm:px-0">
      <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
      <Link aria-label="header logo" className="flex items-center" to="/">
      <Navbar.Brand className="inline-block p-0 m-0">
        <img className="default-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-white@2x.webp' alt='logo' />
        <img className="alt-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-green-dark@2x.webp' alt='logo' />
        <img className="mobile-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-black@2x.webp' alt='logo' />
      </Navbar.Brand>
      </Link>
      </Col>

    </HeaderNav>
    <span onClick={handleToggleModal} className="cursor-pointer float-right pe-[50px] me-[50px] text-dark xs:me-[0px] " style={{ fontSize: '20px' }}>✖</span>
    <Modal show={showModal} onHide={handleCloseModal} centered size="md">
        
        <Modal.Body>
          {/* Your long popup content */}
          {/* You can render the InstaQuote2 component here or any other content */}
         
        </Modal.Body>
        <ClosePopup/>
        {/* You can add a footer if needed */}
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
  </Header>
  {/* Header Start*/}

  <Col xl={{ span: 6, offset: 3 }} md={12} className="lg:px-[30px] xs:py-[100px] md:pt-[100px] md:px-[15px]">

  <div className="p-16 m-20 md:p-16 md:m-20 xs:p-5 md:pt-5 xs:m-5 border border-mediumgray lg:mt-[35px]  md:p-0">
    <Formik>
      {({ isSubmitting, status }) => (
      <Form>

<label className="py-50px font-serif font-medium mb-2 text-darkgray fs-4 ms-1 md:pt-[30px]" htmlFor="">Tell us your address details</label>    <br />                 
          <label className="mb-[15px] " htmlFor="">Address<span className="text-[#fb4f58]">*</span></label>                      
        <textarea  name="address" id='address' cols={"3"} rows={"3"} type="text" value={user.address} onChange={readValue}
          labelclass="mb-[20px]" label={<div className="mb-[15px]">Name <span className="text-[#fb4f58]">*</span>
  </div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]"
  placeholder="Enter your address" />
  <Input name="floor" type="text" id='floor'
    labelclass="mb-[20px]" label={<div className="mb-[15px]">Floor Number <span className="text-[#fb4f58]">*</span>
</div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid border-[#dfdfdf] text-md leading-[initial]"
placeholder="Enter your floor number" value={user.floor} onChange={readValue} />
<Input name="city" type="text"
  id='city' labelclass="mb-[20px]" label={<div className="mb-[15px]">City<span
  className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid
border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your city" value={user.city} onChange={readValue} />
<Input name="pin" type="number"
  id='pin' labelclass="mb-[20px]" label={<div className="mb-[15px]">Pin-Code<span
  className="text-[#fb4f58]">*</span></div>} className="py-[13px] px-[15px] w-full border-[1px] border-solid
border-[#dfdfdf] text-md leading-[initial]" placeholder="Enter your pin-code" value={user.pin} onChange={readValue} />

<div class="form-check p-2 m-3 xs:p-0 xs:m-0 ">
      <input type="checkbox" class="form-check-input checkbox_label fs-5 m-1" id="serviceLift"   name="serviceLift" checked = {user.serviceLift}  onChange={handleInputChange} />
      <label class="form-check-label text-dark font-medium fs-5 mx-3">Service-Lift</label>
    </div>

<Buttons onClick={(e)=>  { e.preventDefault();
                               handleShow(true);}} ariaLabel="register" type="submit" className="btn-fill btn-fancy w-full
  font-medium font-serif rounded-none uppercase md:mb-[15px] sm:mb-0" themeColor={["#502970", "#f34259", "#502970"]} color="#fff" size="md"
  title="Continue" />
  <Modal show={show} fullscreen={fullscreen}>

    <Modal.Body>{/* <span aria-hidden="true" id="close_span" class="float-right fs-1 fw-1 p-5 m-5">×</span> */}
     <StorageRentalPayment newid = {props.newid}/>
    </Modal.Body>
  </Modal>
  </Form>
  )}
  </Formik>
  </div>
  </Col>





  </div>
  );
  };

  export default AddressPage;