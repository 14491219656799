const IconWithTextData_01 = [
    {
        icon: "line-icon-Navigation-LeftWindow text-basecolor text-[40px] text-fastblue",
        title: "Amazing layouts",
        content: "Lorem ipsum is simply dummy text of the printing typesetting ipsum been text.",
    },
    {
        icon: "line-icon-Cursor-Click2 text-basecolor text-[40px] text-fastblue",
        title: "No coding required",
        content: "Lorem ipsum is simply dummy text of the printing typesetting ipsum been text.",
    },
    {
        icon: "line-icon-Like-2 text- text-[40px] text-fastblue",
        title: "Easy to customize",
        content: "Lorem ipsum is simply dummy text of the printing typesetting ipsum been text.",
    },
    {
        icon: "line-icon-Talk-Man text-basecolor text-[40px] text-fastblue",
        title: "Customer satisfaction",
        content: "Lorem ipsum is simply dummy text of the printing typesetting ipsum been text.",
    },
    {
        icon: "line-icon-Heart text-basecolor text-[40px] text-fastblue",
        title: "Huge icon collection",
        content: "Lorem ipsum is simply dummy text of the printing typesetting ipsum been text.",
    },
    {
        icon: "line-icon-Gear-2 text-basecolor text-[40px] text-fastblue",
        title: "Powerfull customize",
        content: "Lorem ipsum is simply dummy text of the printing typesetting ipsum been text.",
    },
]

const IconWithTextData_02 = [
    {
        icon: "line-icon-Navigation-LeftWindow text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
        title: "Comprehensive Services for Storage",
        content: "We'll handle everything! Instead of you having to, we will pack, move, and return your belongings ",
    },
    {
        icon: "line-icon-Cursor-Click2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
        title: "Adaptable Pay as You Use",
        content: "Require more or less storing? We'll make the necessary adjustments to your plan. Terminate agreements at any moment without incurring penalties",
    },
    {
        icon: "line-icon-Archery-2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
        title: "Protection you can rely on",
        content: "Our facilities have motion-sensing cameras, alarms, and round-the-clock security",
    },
    {
        icon: "line-icon-Female text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
        title: "Covered up to 2 lakh",
        content: "Protection from fire, theft, or temporary harm through insurance",
    },
    ,
    {
        icon: "line-icon-Archery-2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
        title: "Personalized ID for every item you own",
        content: "Self storage software are used for both inward and outward transactions",
    },
    {
        icon: "line-icon-Female text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]",
        title: "Superior Quality Packaging",
        content: "Our packaging service has been much appreciated by all of our clients",
    },
]

const IconWithTextData_03 = [
    {
        icon: "line-icon-Navigation-LeftWindow text-gradient bg-gradient-to-r from-[#0039e3] via-[#5e28dd] via-[#741bd9] to-[#8600d4] text-[40px]",
        title: "Amazing layouts",
        content: "Lorem ipsum is simply dummy text the printing typesetting",
    },
    {
        icon: "line-icon-Cursor-Click2 text-gradient bg-gradient-to-r from-[#0039e3] via-[#5e28dd] via-[#741bd9] to-[#8600d4] text-[40px]",
        title: "No coding required",
        content: "Lorem ipsum is simply dummy text the printing typesetting",
    },
    {
        icon: "line-icon-Gear-2 text-gradient bg-gradient-to-r from-[#0039e3] via-[#5e28dd] via-[#741bd9] to-[#8600d4] text-[40px]",
        title: "Easy to customize",
        content: "Lorem ipsum is simply dummy text the printing typesetting",
    },
    {
        icon: "line-icon-Talk-Man text-gradient bg-gradient-to-r from-[#0039e3] via-[#5e28dd] via-[#741bd9] to-[#8600d4] text-[40px]",
        title: "Customer satisfaction",
        content: "Lorem ipsum is simply dummy text the printing typesetting",
    },
]

const IconWithTextData_04 = [
    {
        icon: "line-icon-Cursor-Click2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] via[#f767a6] to-[#ff798e]",
        title: "Innovative business",
        content: "Easy to customize",
    },
    {
        icon: "line-icon-Bakelite text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] via[#f767a6] to-[#ff798e]",
        title: "Expertly marketing",
        content: "High quality services",
    },
    {
        icon: "line-icon-Boy text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] via[#f767a6] to-[#ff798e]",
        title: "Engaging audiences",
        content: "Build perfect websites",
    },
]

const IconWithTextData_05 = [
    {
        icon: "line-icon-Vector-5",
        title: "Good design",
    },
    {
        icon: "line-icon-Police",
        title: "Inspiring ideas",
    },
    {
        icon: "line-icon-Bee",
        title: "Innovative code",
    },
    {
        icon: "line-icon-Medal-2",
        title: "Best quality",
    },
    {
        icon: "line-icon-Idea-5",
        title: "Brand analysis",
    },
]

const IconWithTextData_06 = [
    {
        icon: "line-icon-Android-Store text-fastblue",
        title: "Secure Storage",
        content: "High-quality commercial storage facility, moisture and humidity controlled, featuring 24/7 security and centralized monitoring ",
        linkTitle: "Click Content For Insta quote",
       
    },
    {
        icon: "line-icon-Bakelite text-fastblue",
        title: "Insured storage solutions",
        content: "Reliable storage solutions with comprehensive insurance coverage for added peace of mind. Safeguard your belongings with our insured storage services. ",
        linkTitle: "READ MORE",
       
    },
    {
        icon: "line-icon-Archery-2 text-fastblue",
        title: "Cost-effective ",
        content: "Budget-friendly solutions without compromising quality, ensuring optimal value for money. Our cost-effective services prioritize affordability without sacrificing excellence ",
        linkTitle: "READ MORE",
        
    },
]

const IconWithTextData_07 = [
    {
        icon: "line-icon-Money-Bag text-[#2ed47c]",
        title: "Effective strategy",
        content: "Lorem ipsum dolor sit amet consectetur adipiscing eiusmod tempor incididunt labore et dolore magna tempor.",
        linkTitle: "LEARN MORE",
        link: "/page/our-services"
    },
    {
        icon: "line-icon-Talk-Man text-[#2ed47c]",
        title: "Customer satisfaction",
        content: "Lorem ipsum dolor sit amet consectetur adipiscing eiusmod tempor incididunt labore et dolore magna tempor.",
        linkTitle: "LEARN MORE",
        link: "/page/our-services"
    },
    {
        icon: "line-icon-Gear-2 text-[#2ed47c]",
        title: "Powerfull customize",
        content: "Lorem ipsum dolor sit amet consectetur adipiscing eiusmod tempor incididunt labore et dolore magna tempor. ",
        linkTitle: "LEARN MORE",
        link: "/page/our-services"
    },
]

const IconWithTextData_08 = [
    {
        icon: "line-icon-Cursor-Click2",
        title: "Powerfull options",
        content: "Lorem ipsum dolor consectet eiusmod tempor incididunt",
    },
    {
        icon: "line-icon-Sand-watch2",
        title: "Unlimited variations",
        content: "Lorem ipsum dolor consectet eiusmod tempor incididunt",
    },
    {
        icon: "line-icon-Idea-5",
        title: "Effective strategy",
        content: "Lorem ipsum dolor consectet eiusmod tempor incididunt ",
    },
]

const IconWithTextData_09 = [
    
    {
        icon: "line-icon-Home-5 text-gradient bg-gradient-to-r from-[#ec606c] via-[#ee6362] via-[#ee6757] via-[#ed6c4d] to-[#eb7274]",
        title: "Household Storage",
        
    },
    {
        icon: "line-icon-Office text-gradient bg-gradient-to-r from-[#ec606c] via-[#ee6362] via-[#ee6757] via-[#ed6c4d] to-[#eb7274]",
        title: "Corporate Storage",
    },
    {
        icon: "line-icon-Folder-WithDocument text-gradient bg-gradient-to-r from-[#ec606c] via-[#ee6362] via-[#ee6757] via-[#ed6c4d] to-[#eb7274]",
        title: "Documents Storage",
    },
    {
        icon: "line-icon-Car-3 text-gradient bg-gradient-to-r from-[#ec606c] via-[#ee6362] via-[#ee6757] via-[#ed6c4d] to-[#eb7274]",
        title: "Vehicle Storage ",
    },
]

const IconWithTextData_10 = [
    {
        icon: "line-icon-Navigation-LeftWindow text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] via[#f767a6] to-[#ff798e]",
        title: "Amazing layouts",
        content: "Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text."
    },
    {
        icon: "line-icon-Cursor-Click2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] via[#f767a6] to-[#ff798e]",
        title: "No coding required",
        content: "Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text."
    },
    {
        icon: "line-icon-Like-2 text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] via[#f767a6] to-[#ff798e]",
        title: "Easy to customize",
        content: "Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text."
    },
    {
        icon: "line-icon-Talk-Man text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] via[#f767a6] to-[#ff798e]",
        title: "Customer satisfaction",
        content: "Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text."
    },
    {
        icon: "line-icon-Heart text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] via[#f767a6] to-[#ff798e]",
        title: "Huge icon collection",
        content: "Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text."
    },
    {
        icon: "line-icon-One-FingerTouch text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] via[#f767a6] to-[#ff798e]",
        title: "Responsive layout",
        content: "Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text."
    }
]

const IconWithTextData_11 = [
    {
        icon: "line-icon-Cursor-Click2  text-fastblue",
        title: "Consulting services",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum text"
    },
    {
        icon: "line-icon-Archery-2 text-fastblue",
        title: "Digital marketing",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum text"
    },
    {
        icon: "line-icon-Medal-2 text-fastblue",
        title: "Branding identity",
        content: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum text"
    }
]
export { IconWithTextData_01, IconWithTextData_02, IconWithTextData_03, IconWithTextData_04, IconWithTextData_05, IconWithTextData_06, IconWithTextData_07, IconWithTextData_08, IconWithTextData_09, IconWithTextData_10, IconWithTextData_11 }