import React, { useEffect, useState } from 'react'

// Libraries
import { Col, Collapse, Container, Modal, Navbar, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { m } from 'framer-motion'

// Components
import Buttons from '../../../Components/Button/Buttons'
import { Header, HeaderCart, HeaderLanguage, HeaderNav, Menu, SearchBar } from "../../../Components/Header/Header";

import { fadeIn } from "../../../Functions/GlobalAnimations";
import { InputField } from "../../../Functions/Utilities";
import axios from 'axios'
import ContainerRentals from '../ContainerRentals'
import QuotationPage from './QuotationPage'
import ClosePopup from "../ClosePopup";


// Data
const cartData = [
{
img: "https://via.placeholder.com/600x765",
productName: "Burberry London",
size: "L",
price: "$350.00",
link: "#",
},
{
img: "https://via.placeholder.com/600x765",
productName: "Down Bomber",
size: "L",
price: "$10.00",
link: "#",
},
{
img: "https://via.placeholder.com/600x765",
productName: "Down Bomber",
size: "L",
price: "$299.00",
link: "#",
},
]

const Review = (props) => {
const [review,setReview]=useState([

])

const [quotation,setQuotation]=useState([])


  const [quoteFullscreen, setQuoteFullscreen] = useState(true);
  const [quoteShow, setQuoteShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
const handleCloseModal = () => setShowModal(false);
const handleShowModal = () => setShowModal(true);

const handleToggleModal = () => setShowModal(prev => !prev);

  const handleShow1 = async(breakpoint)=>{
    setQuoteFullscreen(breakpoint);
    setQuoteShow(true)
  }


const handleReview = async()=>{
try {
await axios.get(`${process.env.REACT_APP_API}/api/member/review/${props.newId}`)
.then((res)=>{
// console.log(res.data)
setReview(res.data.extId)


})
.catch((err)=>console.log(err.message))

} catch (error) {
console.log(error.message)
}
}

const handlequotation = async()=>{
  try {
    console.log(props.newId)
    await axios.get(`${process.env.REACT_APP_API}/api/member/quotation/${props.newId}`)
    .then((res)=>{
     console.log(res.data)
    setQuotation(res.data.extid)
    
    
    })
    .catch((err)=>console.log(err.message))
    
    } catch (error) {
    console.log(error.message)
    }
}

useEffect(()=>{
handleReview();
handlequotation()
},[props.newId])

const fLargeTotal = Number(quotation.fLargeValue * review.fLarge);
const fMediumTotal = Number(quotation.fMediumValue * review.fMedium);
const fSmallTotal =Number (quotation.fSmallValue * review.fSmall);
const aLargeTotal = Number(quotation.aLargeValue * review.aLarge);
const aMediumTotal = Number(quotation.aMediumValue * review.aMedium);
const aSmallTotal = Number(quotation.aSmallValue * review.aSmall);
const boxesTotal = Number(quotation.boxes * review.boxes);
const lugguageTotal = Number(quotation.Luggauge * review.luggage);
const packingAndMovingTotal = review.packingCharges;
const basePlanTotal = quotation.basePlan;

const grandTotal = (fLargeTotal + fMediumTotal + fSmallTotal + aLargeTotal + aMediumTotal + aSmallTotal + boxesTotal + lugguageTotal ) ;

const boxCountNew = Number(review.fLarge + review.fMedium + review.fSmall + review.aLarge + review.aMedium + review.aSmall + review.boxes + review.luggage ) 
 
//pdf related

//mutliple of qty for furniture and appliances
const aLargeQty = Number( review.aLarge)
const aMediumQty = Number(review.aMedium)
const aSmallQty = Number( review.aSmall)

const fLargeQty = Number( review.fLarge)
const fMediumQty = Number( review.fMedium)
const fSmallQty = Number(review.fSmall)

//transporatation
let transportCharge;
let manpower;
let packingCharges;
if (boxCountNew >= 1 && boxCountNew <= 15) {
    transportCharge = parseInt(quotation.Transport1);
    manpower = parseInt(quotation.manPower1);
    packingCharges = parseInt(quotation.packing1);
} else if (boxCountNew >= 16 && boxCountNew <=25) {
    transportCharge = parseInt(quotation.Transport2);
    manpower = parseInt(quotation.manPower2);
    packingCharges = parseInt(quotation.packing2);
} else if (boxCountNew >= 26 && boxCountNew <= 40) {
    transportCharge = parseInt(quotation.Transport3);
    manpower = parseInt(quotation.manPower3);
    packingCharges = parseInt(quotation.packing3);
} else if (boxCountNew >= 41 && boxCountNew <= 60) {
    transportCharge = parseInt(quotation.Transport4);
    manpower = parseInt(quotation.manPower4);
    packingCharges = parseInt(quotation.packing4);
} else if (boxCountNew >= 61 && boxCountNew <= 80) {
    transportCharge = parseInt(quotation.Transport5);
    manpower = parseInt(quotation.manPower5);
    packingCharges = parseInt(quotation.packing5);
}else if (boxCountNew >= 81 && boxCountNew <= 100) {
    transportCharge = parseInt(quotation.Transport6);
    manpower = parseInt(quotation.manPower6);
    packingCharges = parseInt(quotation.packing6);
} else if (boxCountNew >= 101 && boxCountNew <= 120) {
    transportCharge = parseInt(quotation.Transport7);
    manpower = parseInt(quotation.manPower7);
    packingCharges = parseInt(quotation.packing7);
} else if (boxCountNew >= 121 && boxCountNew <= 140) {
    transportCharge = parseInt(quotation.Transport8);
    manpower = parseInt(quotation.manPower8);
    packingCharges = parseInt(quotation.packing8);
} else if (boxCountNew >= 141 && boxCountNew <= 160) {
    transportCharge = parseInt(quotation.Transport9);
    manpower = parseInt(quotation.manPower9);
    packingCharges = parseInt(quotation.packing9);
} else if (boxCountNew >= 161 && boxCountNew < 180) {
    transportCharge = parseInt(quotation.Transport10);
    manpower = parseInt(quotation.manPower10);
    packingCharges = parseInt(quotation.packing10);

}



/* if (review.boxes >= 1 && review.boxes <= 15) {
    manpower = parseInt(quotation.manPower1);
} else if (review.boxes >= 16 && review.boxes <=25) {
    manpower = parseInt(quotation.manPower2);
} else if (review.boxes >= 26 && review.boxes <= 40) {
  manpower = parseInt(quotation.manPower3);
} else if (review.boxes >= 41 && review.boxes <= 60) {
  manpower = parseInt(quotation.manPower4);
} else if (review.boxes >= 61 && review.boxes <= 80) {
  manpower = parseInt(quotation.manPower5);
}else if (review.boxes >= 81 && review.boxes <= 100) {
  manpower = parseInt(quotation.manPower6);
} else if (review.boxes >= 101 && review.boxes <= 120) {
  manpower = parseInt(quotation.manPower7);
} else if (review.boxes >= 121 && review.boxes <= 140) {
  manpower = parseInt(quotation.manPower8);
} else if (review.boxes >= 141 && review.boxes <= 160) {
  manpower = parseInt(quotation.manPower9);
} else if (review.boxes >= 161 && review.boxes < 180) {
  manpower = parseInt(quotation.manPower10);
}


let packingCharges;
if (review.boxes >= 1 && review.boxes <= 15) {
    packingCharges = parseInt(quotation.packing1);
} else if (review.boxes >= 16 && review.boxes <=25) {
    packingCharges = parseInt(quotation.packing2);
} else if (review.boxes >= 26 && review.boxes <= 40) {
  packingCharges = parseInt(quotation.packing3);
} else if (review.boxes >= 41 && review.boxes <= 60) {
  packingCharges = parseInt(quotation.packing4);
} else if (review.boxes >= 61 && review.boxes <= 80) {
  packingCharges = parseInt(quotation.packing5);
}else if (review.boxes >= 81 && review.boxes <= 100) {
  packingCharges = parseInt(quotation.packing6);
} else if (review.boxes >= 101 && review.boxes <= 120) {
  packingCharges = parseInt(quotation.packing7);
} else if (review.boxes >= 121 && review.boxes <= 140) {
  packingCharges = parseInt(quotation.packing8);
} else if (review.boxes >= 141 && review.boxes <= 160) {
  packingCharges = parseInt(quotation.packing9);
} else if (review.boxes >= 161 && review.boxes < 180) {
  packingCharges = parseInt(quotation.packing10);
} */

const TransportTotal = parseInt(transportCharge + manpower)
const SubTotal = parseInt(TransportTotal+packingCharges)






return (
<div style={props.style}>
  {/* Header Start */}
  <Header topSpace={{ md: true }} type="reverse-scroll">
    <HeaderNav fluid="fluid" theme="dark" expand="lg"
      className="py-[40px]  px-[35px] md:px-[15px] md:py-[20px] sm:px-0">
      <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
      <Link aria-label="header logo" className="flex items-center" to="/">
      <Navbar.Brand className="inline-block p-0 m-0">
        <img className="default-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-white@2x.webp' alt='logo' />
        <img className="alt-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-green-dark@2x.webp' alt='logo' />
        <img className="mobile-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-black@2x.webp' alt='logo' />
      </Navbar.Brand>
      </Link>
      </Col>

    </HeaderNav>
    <span onClick={handleToggleModal} className="cursor-pointer float-right pe-[50px] me-[50px] text-dark xs:me-[0px] " style={{ fontSize: '20px' }}>✖</span>
    <Modal show={showModal} onHide={handleCloseModal} centered size="md">
        
        <Modal.Body>
          {/* Your long popup content */}
          {/* You can render the InstaQuote2 component here or any other content */}
         
        </Modal.Body>
        <ClosePopup/>
        {/* You can add a footer if needed */}
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
  </Header>
  {/* Header End */}


  {/* Section Start */}
  <m.section className="py-[70px] lg:py-[90px] sm:py-[75px] xs:py-[100px]" {...fadeIn}>
    <Container>
      <Row>
        <Col xl={{ span: 8, offset: 2 }} md={12} className=''>
        

        <div className="bg-lightgray p-[50px] lg:py-[30px] lg:px-[20px] md:py-5">
        

          <div >
            <span className="font-serif text-lg text-darkgray mb-[15px] inline-block font-medium">Hello, <span className="text-[#2ed47c]">{review.name}</span>  kindly check and submit the details</span>
            <table className="w-full total-price-table">

              <tbody>
                <tr>
                  <th className="w-1/2 font-medium text-darkgray">Location
                  <span className="text-sm leading-[15px] mt-[8px] text-[#757575] block ">location:{review.location} </span></th>
                  
                  <th className="text-darkgray ">Storage
                  <span className="text-sm leading-[15px] mt-[8px] text-[#757575] block ">Storage:{review.storage}  </span></th>
                </tr>
                <tr className="shipping">
                  <th className="font-medium text-darkgray">Furniture

                    <span className="text-sm leading-[15px] mt-[8px] text-[#757575] block ">Large:{review.fLarge},
                      Small:{review.fSmall}, Medium:{review.fMedium} </span></th>

                  <th className="font-medium text-darkgray">Appliances

                    <span className="text-sm leading-[15px] mt-[8px] text-[#757575] block ">Large:{review.aLarge}, Small:{review.aSmall},
                    Medium:{review.fMedium}</span></th>

                </tr>
                <tr className="shipping">
                  <th className="font-medium text-darkgray">Boxes  and Luggage

                    <span className="text-sm leading-[15px] mt-[8px] text-[#757575] block ">{review.boxes} and {review.luggage}</span></th>

                  <th className="font-medium text-darkgray">Expected Relocation

                    <span className="text-sm leading-[15px] mt-[8px] text-[#757575] block ">{review.move}</span></th>

                </tr>
                <tr className="shipping">
                  <th className="font-medium text-darkgray">Storage duration
                   
                    <span className="text-sm leading-[15px] mt-[8px] text-[#757575] block ">{review.duration}</span>
                  </th>

                  {/* <th className="font-medium text-darkgray"> Relocation Assistance?
                   
                    <span className="text-sm leading-[15px] mt-[8px] text-[#757575] block ">{review.assistance === "yes" ? "Yes, I need help": "No, I dont need help"}</span></th> */}

                </tr>

              </tbody>

            </table>
          </div>
         



          <div className="mt-[20px] flex justify-end md:items-center md:mb-[50px] sm:justify-center">
          <Buttons onClick={(e)=>{ e.preventDefault();
            handleShow1(true)}} ariaLabel="cart" href="/shop/shopping-cart"
              className="btn-fancy font-medium font-serif uppercase border-[2px] border-[#dbdbdb] rounded-none bg-[#212529]"
              color="#fff" size="sm" themeColor={["#502970", "#f34259", "#502970"]} title="Get quote" />
               <Modal show={quoteShow} fullscreen={quoteFullscreen} >
        
        <Modal.Body  >{/* <span aria-hidden="true" id="close_span" class="float-right fs-1 fw-1 p-5 m-5">×</span> */}
      
      <QuotationPage quote = {grandTotal}  newid={props.newId} review={review} quotation={quotation}   quantityFL= {fLargeQty} totalFurnitureL= {fLargeTotal}   quantityFM= {fMediumQty} totalFurnitureM= {fMediumTotal}  quantityFS= {fSmallQty} totalFurnitureS= {fSmallTotal}   quantityAL={aLargeQty} totalAppliancesL ={aLargeTotal}  quantityAM={aMediumQty} totalAppliancesM={aMediumTotal} quantityAS ={aSmallQty} totalAppliancesS={aSmallTotal} 
         tBoxes = {boxesTotal} tLuggage= {lugguageTotal} pack ={packingCharges} TransportTotal={TransportTotal} SubTotal={SubTotal} baseTotal = {basePlanTotal}
           /> 
        </Modal.Body>
      </Modal>
          </div>

        </div>
        </Col>
      </Row>
    </Container>
  </m.section>
  {/* Section End */}


</div>
)
}

export default Review