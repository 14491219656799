import React, { useState } from "react";

// Libraries
import { Button, Card, Col, Container,Modal, Navbar, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Parallax } from "react-scroll-parallax";
import { Formik, Form } from 'formik';
import { m, AnimatePresence } from 'framer-motion';
import * as Yup from 'yup';

// Component
import Header, { HeaderNav, Menu } from "../../Components/Header/Header";
import { Input } from "../../Components/Form/Form";
import Buttons from "../../Components/Button/Buttons";
import axios from "axios";
import { fadeIn } from "../../Functions/GlobalAnimations";
import ClosePopup from "./ClosePopup";



// Data








const ContainerRentals = (props) => {
const swiperRef = React.useRef(null);
const navigate = useNavigate() 
const [show, setShow] = useState(false);
const [showModal, setShowModal] = useState(false);
const handleCloseModal = () => setShowModal(false);
const handleShowModal = () => setShowModal(true);

const handleToggleModal = () => setShowModal(prev => !prev);



return (
<div style={props.style}>

  {/* Header Start*/}
  <Header topSpace={{ md: true }} type="reverse-scroll">
    <HeaderNav fluid="fluid" theme="dark" expand="lg"
      className="py-[40px]  px-[35px] md:px-[15px] md:py-[20px] sm:px-0">
      <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
      <Link aria-label="header logo" className="flex items-center" to="/">
      <Navbar.Brand className="inline-block p-0 m-0">
        <img className="default-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-white@2x.webp' alt='logo' />
        <img className="alt-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-green-dark@2x.webp' alt='logo' />
        <img className="mobile-logo" width="111" height="36" loading="lazy" src='./assets/img/webp/genx-logo.png'
          data-rjs='/assets/img/webp/logo-black@2x.webp' alt='logo' />
      </Navbar.Brand>
      </Link>
      </Col>

    </HeaderNav>
    <span onClick={handleToggleModal} className="cursor-pointer float-right pe-[50px] me-[50px] text-dark xs:me-[0px] " style={{ fontSize: '20px' }}>✖</span>
    <Modal show={showModal} onHide={handleCloseModal} centered size="md">
        
        <Modal.Body>
          {/* Your long popup content */}
          {/* You can render the InstaQuote2 component here or any other content */}
         
        </Modal.Body>
        <ClosePopup/>
        {/* You can add a footer if needed */}
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
  </Header>
  {/* Header Start*/}

  <m.section className="py-[70px] lg:py-[90px] sm:py-[75px] xs:py-[50px]" {...fadeIn}>
  <p class="cls-duradds" style={{ color: '#fff' }}>Ilovemycoutry</p>
    <Container>
      <Row>
        <Col xl={{ span: 8, offset: 2 }} md={12} className=''>
        

        <div className="bg-lightgray p-[50px] lg:py-[30px] lg:px-[20px] md:py-5">
        

        <div >
            {/* <span className="font-serif text-lg text-darkgray mb-[15px] inline-block font-medium">Hello, <span className="text-[#2ed47c]"></span>  kindly check and submit the details</span> */}

            
            <h1 className="font-serif text-center text-lg text-success mt-5 mb-[15px] inline-block fs-1 font-medium">
Thank you for reaching out! </h1>
            <h3 className="fs-4 text-center ">
Our experts will be in touch with you shortly.</h3>

            <hr />
            <p>
            
To assist you with your storage request, we have assigned a dedicated specialized relationship manager, Amit <a href="tel:+919866012094" className="text-success fw-3">9866012094</a>
              
            </p>
            {/* <table className="w-full total-price-table">


              <tbody>
                <tr className="shipping">
                  <th className="font-medium text-darkgray">Furniture

                    <span className="text-sm leading-[15px] mt-[8px] text-[#757575] block ">,
                     </span></th>

                  <th className="font-medium text-darkgray">Appliances

                    <span className="text-sm leading-[15px] mt-[8px] text-[#757575] block "></span></th>

                </tr>
                <tr className="shipping">
                  <th className="font-medium text-darkgray">Boxes

                    <span className="text-sm leading-[15px] mt-[8px] text-[#757575] block "></span></th>

                  <th className="font-medium text-darkgray">Expected Relocation

                    <span className="text-sm leading-[15px] mt-[8px] text-[#757575] block "></span></th>

                </tr>
                <tr className="shipping">
                  <th className="font-medium text-darkgray">Storage duration
                   
                    <span className="text-sm leading-[15px] mt-[8px] text-[#757575] block "></span>
                  </th>

                  <th className="font-medium text-darkgray"> Relocation Assistance?
                   
                    <span className="text-sm leading-[15px] mt-[8px] text-[#757575] block "></span></th>

                </tr>

              </tbody>

            </table> */}
            <br />
            <p>Looking forward to serve you</p>
            <br />
           
            <p>Best Regards,</p>
            <p>Team GenX </p>
          </div>
         



          {/* <div className="mt-[20px] flex justify-end md:items-center md:mb-[50px] sm:justify-center">
            <Buttons ariaLabel="cart" href="/shop/shopping-cart"
              className="btn-fancy font-medium font-serif uppercase border-[2px] border-[#dbdbdb] rounded-none bg-[#212529]"
              color="#000" size="sm" themeColor="#212529" title="Get quote" />
          </div> */}

        </div>
        </Col>
      </Row>
    </Container>
  </m.section>

  





</div>
);
};

export default ContainerRentals;