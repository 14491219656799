const HeaderData = [
    {
        title: 'Relocation Services',
        dropdown: [
            {
                title: 'Packing and Moving',
                link: `/service`,
            },
            {
                title: 'Transportation',
                link: '/service'
            },
            {
                title: 'Export Packing',
                link: '/service'
            },
            {
                title: 'Business Relocation',
                link: '/service'
            },
            {
                title: 'Household Relocation',
                link: '/service'
            },
            {
                title: 'Data Server Relocation',
                link: '/service'
             },
             {
                title: 'Container Rental Services',
                link: '/service'
             },
        ]
    },
   
    {
        title: 'Storage',
        dropdown: [
            {
                title: 'Personal Storage',
                link: `/self-storage-in-hyderabad`,
            },
            {
                title: 'Business Storage',
                link: '/business-storage'
            },
        ]
        // dropdown: [
        //     {
        //         title: 'About',
        //         dropdown: [
        //             {
        //                 title: 'About me',
        //                 link: '/page/about-me'
        //             },
        //             {
        //                 title: 'About us',
        //                 link: '/page/about-us'
        //             },
        //             {
        //                 title: 'Our story',
        //                 link: '/page/our-story'
        //             },
        //             {
        //                 title: 'Who we are',
        //                 link: '/page/who-we-are'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Services',
        //         dropdown: [
        //             {
        //                 title: 'Our services',
        //                 link: '/page/our-services'
        //             },
        //             {
        //                 title: 'What we offer',
        //                 link: '/page/what-we-offer'
        //             },
        //             {
        //                 title: 'Our process',
        //                 link: '/page/our-process'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Contact',
        //         dropdown: [
        //             {
        //                 title: 'Contact simple',
        //                 link: '/page/contact-simple'
        //             },
        //             {
        //                 title: 'Contact classic',
        //                 link: '/page/contact-classic'
        //             },
        //             {
        //                 title: 'Contact modern',
        //                 link: '/page/contact-modern'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Additional pages',
        //         dropdown: [
        //             {
        //                 title: 'Our team',
        //                 link: '/page/our-team'
        //             },
        //             {
        //                 title: 'Latest news',
        //                 link: '/page/latest-news'
        //             },
        //             {
        //                 title: 'Pricing packages',
        //                 link: '/page/pricing-packages'
        //             },
        //             {
        //                 title: 'Error 404',
        //                 link: '/page/error-404'
        //             },
        //             {
        //                 title: 'Maintenance',
        //                 link: '/page/maintenance'
        //             },
        //             {
        //                 title: 'Coming soon',
        //                 link: '/page/coming-soon'
        //             },
        //             {
        //                 title: 'Coming soon - V2',
        //                 link: '/page/coming-soon-V2'
        //             },
        //             {
        //                 title: "FAQ's",
        //                 link: '/page/faq-s'
        //             },
        //             {
        //                 title: "Search result",
        //                 link: '/page/search-result'
        //             },
        //         ]
        //     },
        // ]
    },
    {
        title: 'About',
        link:'/about-us'
        // dropdown: [
        //     {
        //         title: 'Portfolio classic',
        //         dropdown: [
        //             {
        //                 title: 'Classic 2 column',
        //                 link: '/portfolio/portfolio-classic-two-column'
        //             },
        //             {
        //                 title: 'Classic 3 column',
        //                 link: '/portfolio/portfolio-classic-three-column'
        //             },
        //             {
        //                 title: 'Classic 4 column',
        //                 link: '/portfolio/portfolio-classic-four-column'
        //             },
        //             {
        //                 title: 'Classic masonry',
        //                 link: '/portfolio/portfolio-classic-masonry'
        //             },
        //             {
        //                 title: 'Classic metro',
        //                 link: '/portfolio/portfolio-classic-metro'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Portfolio boxed',
        //         dropdown: [
        //             {
        //                 title: 'Boxed 2 column',
        //                 link: '/portfolio/portfolio-boxed-two-column'
        //             },
        //             {
        //                 title: 'Boxed 3 column',
        //                 link: '/portfolio/portfolio-boxed-three-column'
        //             },
        //             {
        //                 title: 'Boxed 4 column',
        //                 link: '/portfolio/portfolio-boxed-four-column'
        //             },
        //             {
        //                 title: 'Boxed masonry',
        //                 link: '/portfolio/portfolio-boxed-masonry'
        //             },
        //             {
        //                 title: 'Boxed metro',
        //                 link: '/portfolio/portfolio-boxed-metro'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Portfolio colorful',
        //         dropdown: [
        //             {
        //                 title: 'Colorful 2 column',
        //                 link: '/portfolio/portfolio-colorful-two-column'
        //             },
        //             {
        //                 title: 'Colorful 3 column',
        //                 link: '/portfolio/portfolio-colorful-three-column'
        //             },
        //             {
        //                 title: 'Colorful 4 column',
        //                 link: '/portfolio/portfolio-colorful-four-column'
        //             },
        //             {
        //                 title: 'Colorful masonry',
        //                 link: '/portfolio/portfolio-colorful-masonry'
        //             },
        //             {
        //                 title: 'Colorful metro',
        //                 link: '/portfolio/portfolio-colorful-metro'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Portfolio bordered',
        //         dropdown: [
        //             {
        //                 title: 'Bordered 2 column',
        //                 link: '/portfolio/portfolio-bordered-two-column'
        //             },
        //             {
        //                 title: 'Bordered 3 column',
        //                 link: '/portfolio/portfolio-bordered-three-column'
        //             },
        //             {
        //                 title: 'Bordered 4 column',
        //                 link: '/portfolio/portfolio-bordered-four-column'
        //             },
        //             {
        //                 title: 'Bordered masonry',
        //                 link: '/portfolio/portfolio-bordered-masonry'
        //             },
        //             {
        //                 title: 'Bordered metro',
        //                 link: '/portfolio/portfolio-bordered-metro'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Portfolio overlay',
        //         dropdown: [
        //             {
        //                 title: 'Overlay 2 column',
        //                 link: '/portfolio/portfolio-overlay-two-column'
        //             },
        //             {
        //                 title: 'Overlay 3 column',
        //                 link: '/portfolio/portfolio-overlay-three-column'
        //             },
        //             {
        //                 title: 'Overlay 4 column',
        //                 link: '/portfolio/portfolio-overlay-four-column'
        //             },
        //             {
        //                 title: 'Overlay masonry',
        //                 link: '/portfolio/portfolio-overlay-masonry'
        //             },
        //             {
        //                 title: 'Overlay metro',
        //                 link: '/portfolio/portfolio-overlay-metro'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Portfolio switch image',
        //         dropdown: [
        //             {
        //                 title: 'Switch Image 2 column',
        //                 link: '/portfolio/portfolio-switch-image-two-column'
        //             },
        //             {
        //                 title: 'Switch Image 3 column',
        //                 link: '/portfolio/portfolio-switch-image-three-column'
        //             },
        //             {
        //                 title: 'Switch Image 4 column',
        //                 link: '/portfolio/portfolio-switch-image-four-column'
        //             },
        //             {
        //                 title: 'Switch Image masonry',
        //                 link: '/portfolio/portfolio-switch-image-masonry'
        //             },
        //             {
        //                 title: 'Switch Image metro',
        //                 link: '/portfolio/portfolio-switch-image-metro'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Portfolio other',
        //         dropdown: [
        //             {
        //                 title: 'Portfolio scattered',
        //                 link: '/portfolio/portfolio-scattered'
        //             },
        //             {
        //                 title: 'Justified gallery',
        //                 link: '/portfolio/portfolio-justified-gallery'
        //             },
        //             {
        //                 title: 'Portfolio slider',
        //                 link: '/portfolio/portfolio-slider'
        //             },
        //         ]

        //     },
        //     {
        //         title: 'Single project page',
        //         dropdown: [
        //             {
        //                 title: 'Single project page 01',
        //                 link: '/portfolio/single-project-page-01'
        //             },
        //             {
        //                 title: 'Single project page 02',
        //                 link: '/portfolio/single-project-page-02'
        //             },
        //             {
        //                 title: 'Single project page 03',
        //                 link: '/portfolio/single-project-page-03'
        //             },
        //             {
        //                 title: 'Single project page 04',
        //                 link: '/portfolio/single-project-page-04'
        //             },
        //             {
        //                 title: 'Single project page 05',
        //                 link: '/portfolio/single-project-page-05'
        //             }
        //         ]
        //     }
        // ],
    },
    {
        title: 'Our Story',
        link:'/our-story'
        // megamenu: [
        //     {
        //         title: 'General',
        //         dropdown: [
        //             {
        //                 title: 'Accordions',
        //                 icon: 'ti-layout-accordion-separated',
        //                 link: '/elements/accordions',
        //             },
        //             {
        //                 title: 'Buttons',
        //                 icon: 'ti-mouse',
        //                 link: '/elements/buttons',
        //             },
        //             {
        //                 title: 'Teams',
        //                 icon: 'ti-user',
        //                 link: '/elements/teams',
        //             },
        //             {
        //                 title: 'Team carousel',
        //                 icon: 'ti-layout-slider-alt',
        //                 link: '/elements/team-carousel',
        //             },
        //             {
        //                 title: 'Clients',
        //                 icon: 'ti-id-badge',
        //                 link: '/elements/clients',
        //             },
        //             {
        //                 title: 'Client carousel',
        //                 icon: 'ti-layout-slider',
        //                 link: '/elements/client-carousel',
        //             },
        //             {
        //                 title: 'Subscribe',
        //                 icon: 'ti-crown',
        //                 link: '/elements/subscribe',
        //             },
        //             {
        //                 title: 'Call to action',
        //                 icon: 'ti-eye',
        //                 link: '/elements/call-to-action',
        //             },
        //             {
        //                 title: 'Tab',
        //                 icon: 'ti-layout-tab',
        //                 link: '/elements/tab',
        //             },
        //             {
        //                 title: 'Google map',
        //                 icon: 'ti-location-pin',
        //                 link: '/elements/google-map'
        //             },
        //             {
        //                 title: 'Contact form',
        //                 icon: 'ti-clipboard',
        //                 link: '/elements/contact-form'
        //             },
        //             {
        //                 title: 'Image gallery',
        //                 icon: 'ti-gallery',
        //                 link: '/elements/image-gallery',
        //             }
        //         ],
        //     },
        //     {
        //         title: 'Content & infographics',
        //         dropdown: [
        //             {
        //                 title: 'Progress bar',
        //                 icon: 'icon-hourglass',
        //                 link: '/elements/progress-bar',
        //             },
        //             {
        //                 title: 'Icon with text',
        //                 icon: 'ti-layout-media-left',
        //                 link: '/elements/icon-with-text',
        //             },
        //             {
        //                 title: 'Over line icon box',
        //                 icon: 'ti-write',
        //                 link: '/elements/overline-icon-box'
        //             },
        //             {
        //                 title: 'Custom icon with text',
        //                 icon: 'ti-vector',
        //                 link: '/elements/custom-icon-with-text'
        //             },
        //             {
        //                 title: 'Counters',
        //                 icon: 'ti-timer',
        //                 link: '/elements/counters'
        //             },
        //             {
        //                 title: 'Countdown',
        //                 icon: 'ti-alarm-clock',
        //                 link: '/elements/countdown',
        //             },
        //             {
        //                 title: 'Pie chart',
        //                 icon: 'ti-pie-chart',
        //                 link: '/elements/pie-chart',
        //             },
        //             {
        //                 title: 'Fancy text box',
        //                 icon: 'ti-layout-cta-center',
        //                 link: '/elements/fancy-text-box',
        //             },
        //             {
        //                 title: 'Text box',
        //                 icon: 'ti-layout-cta-left',
        //                 link: '/elements/text-box',
        //             },
        //             {
        //                 title: 'Fancy text',
        //                 icon: 'ti-text',
        //                 link: '/elements/fancy-text',
        //             }
        //         ],
        //     },
        //     {
        //         title: 'Interactive',
        //         dropdown: [
        //             {
        //                 title: 'Testimonials',
        //                 icon: 'ti-thought',
        //                 link: '/elements/testimonials',
        //             },
        //             {
        //                 title: 'Testimonials carousel',
        //                 icon: 'ti-comments',
        //                 link: '/elements/testimonials-carousel',
        //             },
        //             {
        //                 title: 'Video',
        //                 icon: 'ti-video-camera',
        //                 link: '/elements/video',
        //             },
        //             {
        //                 title: 'Interactive banners',
        //                 icon: 'ti-image',
        //                 link: '/elements/interactive-banners',
        //             },
        //             {
        //                 title: 'Services',
        //                 icon: 'ti-bookmark-alt',
        //                 link: '/elements/services',
        //             },
        //             {
        //                 title: 'Info banner',
        //                 icon: 'ti-layout-slider',
        //                 link: '/elements/info-banner',
        //             },
        //             {
        //                 title: 'Rotate box',
        //                 icon: 'ti-package',
        //                 link: '/elements/rotate-box',
        //             },
        //             {
        //                 title: 'Process step',
        //                 icon: 'ti-layers',
        //                 link: '/elements/process-step',
        //             },
        //             {
        //                 title: 'Instagram',
        //                 icon: 'ti-instagram',
        //                 link: '/elements/instagram',
        //             },
        //             {
        //                 title: 'Parallax scrolling',
        //                 icon: 'ti-exchange-vertical',
        //                 link: '/elements/parallax-scrolling',
        //             },
        //             {
        //                 title: 'Text slider',
        //                 icon: 'ti-layout-media-overlay',
        //                 link: '/elements/text-slider',
        //             }
        //         ],
        //     },
        //     {
        //         title: 'Text & containers',
        //         dropdown: [
        //             {
        //                 title: 'Heading',
        //                 icon: 'ti-text',
        //                 link: '/elements/heading',
        //             },
        //             {
        //                 title: 'Drop caps',
        //                 icon: 'ti-smallcap',
        //                 link: '/elements/dropcaps',
        //             },
        //             {
        //                 title: 'Columns',
        //                 icon: 'ti-layout',
        //                 link: '/elements/columns',
        //             },
        //             {
        //                 title: 'Blockquote',
        //                 icon: 'ti-quote-left',
        //                 link: '/elements/blockquote',
        //             },
        //             {
        //                 title: 'Highlights',
        //                 icon: 'ti-underline',
        //                 link: '/elements/highlights',
        //             },
        //             {
        //                 title: 'Message box',
        //                 icon: 'ti-layout-media-right-alt',
        //                 link: '/elements/message-box',
        //             },
        //             {
        //                 title: 'Social icons',
        //                 icon: 'ti-user',
        //                 link: '/elements/social-icons',
        //             },
        //             {
        //                 title: 'Lists',
        //                 icon: 'ti-list',
        //                 link: '/elements/lists',
        //             },
        //             {
        //                 title: 'Separators',
        //                 icon: 'ti-layout-line-solid',
        //                 link: '/elements/separators',
        //             },
        //             {
        //                 title: 'Pricing table',
        //                 icon: 'ti-layout-column2',
        //                 link: '/elements/pricing-table',
        //             }
        //         ],
        //     }
        // ]
    },
    {
        title: 'FAQs',
        link: '/faq-s'
        // dropdown: [
        //     {
        //         title: 'Header and menu',
        //         dropdown: [
        //             {
        //                 title: 'Transparent header',
        //                 link: '/headers/transparent-header'
        //             },
        //             {
        //                 title: 'White header',
        //                 link: '/headers/white-header'
        //             },
        //             {
        //                 title: 'Dark header',
        //                 link: '/headers/dark-header'
        //             },
        //             {
        //                 title: 'Header with top bar',
        //                 link: '/headers/header-with-top-bar'
        //             },
        //             {
        //                 title: 'Header with push',
        //                 link: '/headers/header-with-push'
        //             },
        //             {
        //                 title: 'Center navigation',
        //                 link: '/headers/center-navigation'
        //             },
        //             {
        //                 title: 'Center logo',
        //                 link: '/headers/center-logo'
        //             },
        //             {
        //                 title: 'Top logo',
        //                 link: '/headers/top-logo'
        //             },
        //             {
        //                 title: 'One page navigation',
        //                 link: '/headers/one-page-navigation'
        //             },
        //             {
        //                 title: 'Hamburger menu',
        //                 dropdown: [
        //                     {
        //                         title: 'Hamburger menu',
        //                         link: '/headers/hamburger-menu'
        //                     },
        //                     {
        //                         title: 'Hamburger menu modern',
        //                         link: '/headers/hamburger-menu-modern'
        //                     },
        //                     {
        //                         title: 'Hamburger menu half',
        //                         link: '/headers/hamburger-menu-half'
        //                     }
        //                 ]
        //             },
        //             {
        //                 title: 'Left menu',
        //                 dropdown: [
        //                     {
        //                         title: 'Left menu classic',
        //                         link: '/headers/left-menu-classic'
        //                     },
        //                     {
        //                         title: 'Left menu modern',
        //                         link: '/headers/left-menu-modern'
        //                     }
        //                 ]
        //             },
        //             {
        //                 title: 'Header type',
        //                 dropdown: [
        //                     {
        //                         title: 'Always fixed',
        //                         link: '/headers/header-always-fixed'
        //                     },
        //                     {
        //                         title: 'Disable fixed',
        //                         link: '/headers/header-disable-fixed'
        //                     },
        //                     {
        //                         title: 'Reverse scroll',
        //                         link: '/headers/header-reverse-scroll'
        //                     },
        //                     {
        //                         title: 'Responsive sticky',
        //                         link: '/headers/header-responsive-sticky'
        //                     }
        //                 ]
        //             },
        //             {
        //                 title: 'Mobile menu',
        //                 dropdown: [
        //                     {
        //                         title: 'Classic',
        //                         link: '/headers/mobile-menu-classic'
        //                     },
        //                     {
        //                         title: 'Modern',
        //                         link: '/headers/mobile-menu-modern'
        //                     },
        //                     {
        //                         title: 'Full screen',
        //                         link: '/headers/mobile-menu-full-screen'
        //                     }
        //                 ]
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Footer',
        //         dropdown: [
        //             {
        //                 title: 'Footer style 01',
        //                 link: '/footers/footer-style-01'
        //             },
        //             {
        //                 title: 'Footer style 02',
        //                 link: '/footers/footer-style-02'
        //             },
        //             {
        //                 title: 'Footer style 03',
        //                 link: '/footers/footer-style-03'
        //             },
        //             {
        //                 title: 'Footer style 04',
        //                 link: '/footers/footer-style-04'
        //             },
        //             {
        //                 title: 'Footer style 05',
        //                 link: '/footers/footer-style-05'
        //             },
        //             {
        //                 title: 'Footer style 06',
        //                 link: '/footers/footer-style-06'
        //             },
        //             {
        //                 title: 'Footer style 07',
        //                 link: '/footers/footer-style-07'
        //             },
        //             {
        //                 title: 'Footer style 08',
        //                 link: '/footers/footer-style-08'
        //             },
        //             {
        //                 title: 'Footer style 09',
        //                 link: '/footers/footer-style-09'
        //             },
        //             {
        //                 title: 'Footer style 10',
        //                 link: '/footers/footer-style-10'
        //             },
        //             {
        //                 title: 'Footer style 11',
        //                 link: '/footers/footer-style-11'
        //             },
        //             {
        //                 title: 'Footer style 12',
        //                 link: '/footers/footer-style-12'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Page title',
        //         dropdown: [
        //             {
        //                 title: 'Left alignment',
        //                 link: '/page-title/left-alignment'
        //             },
        //             {
        //                 title: 'Right alignment',
        //                 link: '/page-title/right-alignment'
        //             },
        //             {
        //                 title: 'Center alignment',
        //                 link: '/page-title/center-alignment'
        //             },
        //             {
        //                 title: 'Colorful style',
        //                 link: '/page-title/colorful-style'
        //             },
        //             {
        //                 title: 'Big typography',
        //                 link: '/page-title/big-typography'
        //             },
        //             {
        //                 title: 'Parallax background',
        //                 link: '/page-title/parallax-background'
        //             },
        //             {
        //                 title: 'Separate breadcrumbs',
        //                 link: '/page-title/separate-breadcrumbs'
        //             },
        //             {
        //                 title: 'Gallery background',
        //                 link: '/page-title/gallery-background'
        //             },
        //             {
        //                 title: 'Background video',
        //                 link: '/page-title/background-video'
        //             },
        //             {
        //                 title: 'Mini version',
        //                 link: '/page-title/mini-version'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Modal popup',
        //         dropdown: [
        //             {
        //                 title: 'Simple modal',
        //                 link: '/model-popup/simple-modal'
        //             },
        //             {
        //                 title: 'Subscription',
        //                 link: '/model-popup/subscription'
        //             },
        //             {
        //                 title: 'Contact form',
        //                 link: '/model-popup/contact-form'
        //             },
        //             {
        //                 title: 'Youtube video',
        //                 link: '/model-popup/youtube-video'
        //             },
        //             {
        //                 title: 'Vimeo video',
        //                 link: '/model-popup/vimeo-video'
        //             },
        //             {
        //                 title: 'Google map',
        //                 link: '/model-popup/Google-map'
        //             }
        //         ]
        //     },
        //     {
        //         title: 'Icon packs',
        //         dropdown: [
        //             {
        //                 title: 'Icons mind line',
        //                 link: '/iconsmindline'
        //             },
        //             {
        //                 title: 'Icons mind solid',
        //                 link: '/iconsmindsolid'
        //             },
        //             {
        //                 title: 'Feather',
        //                 link: '/feather'
        //             },
        //             {
        //                 title: 'Font awesome',
        //                 link: '/fontawesome'
        //             },
        //             {
        //                 title: 'ET line',
        //                 link: '/ETlineicon'
        //             },
        //             {
        //                 title: 'Themify',
        //                 link: '/themify'
        //             },
        //             {
        //                 title: 'Simple line',
        //                 link: '/simple-line'
        //             }
        //         ]
        //     },
        //     {
        //         title: "Animations",
        //         link: "/animation"
        //     }
        // ]
    },
    {
        title: 'Google Reviews',
        link:"/google-reviews"
        // dropdown: [
        //     {
        //         title: 'Blog grid',
        //         link: '/blogs/blog-grid'
        //     },
        //     {
        //         title: 'Blog masonry',
        //         link: '/blogs/blog-masonry'
        //     },
        //     {
        //         title: 'Blog classic',
        //         link: '/blogs/blog-classic'
        //     },
        //     {
        //         title: 'Blog simple',
        //         link: '/blogs/blog-simple'
        //     },
        //     {
        //         title: 'Blog side image',
        //         link: '/blogs/blog-side-image'
        //     },
        //     {
        //         title: 'Blog metro',
        //         link: '/blogs/blog-metro'
        //     },
        //     {
        //         title: 'Blog overlay image',
        //         link: '/blogs/blog-overlay-image'
        //     },
        //     {
        //         title: 'Blog modern',
        //         link: '/blogs/blog-modern'
        //     },
        //     {
        //         title: 'Blog clean',
        //         link: '/blogs/blog-clean'
        //     },
        //     {
        //         title: 'Blog widget',
        //         link: '/blogs/blog-widget'
        //     },
        //     {
        //         title: 'Blog standard',
        //         link: '/blogs/blog-standard'
        //     },
        //     {
        //         title: 'Post layout',
        //         dropdown: [
        //             {
        //                 title: 'Blog post layout 01',
        //                 link: '/blogs/blog-post-layout-01'
        //             },
        //             {
        //                 title: 'Blog post layout 02',
        //                 link: '/blogs/blog-post-layout-02'
        //             },
        //             {
        //                 title: 'Blog post layout 03',
        //                 link: '/blogs/blog-post-layout-03'
        //             },
        //             {
        //                 title: 'Blog post layout 04',
        //                 link: '/blogs/blog-post-layout-04'
        //             },
        //             {
        //                 title: 'Blog post layout 05',
        //                 link: '/blogs/blog-post-layout-05'
        //             },
        //         ]
        //     },
        //     {
        //         title: 'Post types',
        //         dropdown: [
        //             {
        //                 title: 'Standard post',
        //                 link: '/blog-types/blog-standard-post/1'
        //             },
        //             {
        //                 title: 'Gallery post',
        //                 link: '/blog-types/blog-gallery-post/1'
        //             },
        //             {
        //                 title: 'Slider post',
        //                 link: '/blog-types/blog-slider-post/1'
        //             },
        //             {
        //                 title: 'HTML5 video post',
        //                 link: '/blog-types/blog-html5-video-post/1'
        //             },
        //             {
        //                 title: 'Youtube video post',
        //                 link: '/blog-types/blog-youtube-video-post/1'
        //             },
        //             {
        //                 title: 'Vimeo video post',
        //                 link: '/blog-types/blog-vimeo-video-post/1'
        //             },
        //             {
        //                 title: 'Audio post',
        //                 link: '/blog-types/blog-audio-post/1'
        //             },
        //             {
        //                 title: 'Blockquote post',
        //                 link: '/blog-types/blog-blockquote-post/1'
        //             },
        //             {
        //                 title: 'Full width post',
        //                 link: '/blog-types/blog-full-width-post/1'
        //             },
        //         ]
        //     },
        // ]
    },
    {
        title: "Contact",
        link:'/contact-us'

        // megamenu: [
        //     {
        //         title: 'Shop layout',
        //         dropdown: [
        //             {
        //                 title: 'Shop wide',
        //                 link: '/shop/shop-wide'
        //             },
        //             {
        //                 title: 'Left sidebar',
        //                 link: '/shop/left-sidebar'
        //             },
        //             {
        //                 title: 'Right sidebar',
        //                 link: '/shop/right-sidebar'
        //             },
        //             {
        //                 title: 'Only categories',
        //                 link: '/shop/only-categories'
        //             },
        //             {
        //                 title: 'Single product',
        //                 link: '/shop/single-product'
        //             },
        //         ],
        //     },
        //     {
        //         title: 'Utility pages ',
        //         dropdown: [
        //             {
        //                 title: 'Shopping cart',
        //                 link: '/shop/shopping-cart'
        //             },
        //             {
        //                 title: 'Checkout',
        //                 link: '/shop/checkout'
        //             },
        //             {
        //                 title: 'Login / Register',
        //                 link: '/shop/login-register'
        //             }
        //         ]
        //     },
        //     {
        //         dropdown: [
        //             {
        //                 link: "/",
        //                 img: "/assets/img/webp/menu-banner-02.webp",
        //             }
        //         ]
        //     }
        // ],
    }
]

export default HeaderData