import React, { useState } from "react";

// Libraries
import { Button, Card, Col, Container, Modal, Navbar, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Parallax } from "react-scroll-parallax";
import { Formik, Form } from 'formik';
import { m, AnimatePresence } from 'framer-motion';
import * as Yup from 'yup';

// Component
import Header, { HeaderNav, Menu } from "../../Components/Header/Header";
import { Input } from "../../Components/Form/Form";
import Buttons from "../../Components/Button/Buttons";
import axios from "axios";
import InstaQuote2 from "./InstaQuote2";
import { useFormik } from 'formik';

// Data

const ClosePopup = (props) => {
const swiperRef = React.useRef(null);
const navigate = useNavigate() 
const [location, setLocation] = useState({
  location:"",
  storage:"",
  name:"",
  email:"",
  mobilenumber:""
})





const [fullscreen, setFullscreen] = useState(true);
const [show, setShow] = useState(false);
const [id,setID]=useState(null)
const [showModal, setShowModal] = useState(false);
const handleCloseModal = () => setShowModal(false);
const handleShowModal = () => setShowModal(true);





const handleShow1 = async(breakpoint)=>{
  setFullscreen(breakpoint);
  setShow(true)

}
const handleToggleModal = () => setShowModal(prev => !prev);
const handleClose = ()=>{

 window.location.reload();
}



return (
<div style={props.style}>

 
 

  <Col xl={{ span: 10, offset: 1}} md={12} >
    

 

        <div style={{  marginTop: '20px' }}>
          <img src="./assets/img/webp/popup-image.jpg" style={{ maxWidth: '100%'}} alt="" />
        </div>
       <div>
        <p className="fs-2 mt-3">Want help over the phone</p>
        <p>Just drop WhatsApp message and we will help you with your storage requirement!</p>
       </div>
        <div className="mt-4">
         <button className="btn btn-light px-[70px] py-[10px] w-[100%] text-info border border-info rounded-none "><a href="https://wa.me/+919866012094">Chat with us on WhatsApp</a></button>
        </div>

        <div className="mt-4 mb-4">
         <button onClick={handleClose}
          className="btn btn-light px-[70px] py-[10px] w-[100%] text-info border border-info rounded-none ">Close Form</button>
        </div>

     
     

 </Col>





</div>
);
};

export default ClosePopup;